//prutl-frontend-npm6node14/src/components/userManagement/UserList.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  deleteUser,
  updateUser,
} from "../../redux/slices/userSlice";
import { registerUser } from "../../redux/slices/authSlice";

const UserList = () => {
  const dispatch = useDispatch();
  // const { users, loading, error } = useSelector((state) => state.users);
  const { users, loading, error } = useSelector((state) => state.users);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    user_code: null,
    name: "",
    email: "",
    password: "",
    phone_number: "",
    user_type: "",
    membership_id: null,
    usergroup_id: null,
    referrer_id: null,
    city: "",
    county: "",
    state: "",
    country: "",
    pin_code: "",
    remark: "",
  });
  // console.log('users in UserList page++++++++++++++++',users)
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = (userId) => {
    dispatch(deleteUser(userId));
    //  console.log('userId sent on delete button',user_id)
  };
  const handleEdit = (user) => {
    setEditingUser({ ...user });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingUser) {
      setEditingUser({ ...editingUser, [name]: value });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};

    if (!formData.name || !/^[a-zA-Z\s]+$/.test(formData.name)) {
      newErrors.name = "Please enter a valid name with surname.";
    }

    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!formData.password || formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }

    if (
      !formData.phone_number ||
      !/^\+?[1-9]\d{1,14}$/.test(formData.phone_number)
    ) {
      newErrors.phone_number =
        "Please enter a valid phone number with country code.";
    }

    // if (!formData.country) {
    //   newErrors.country = "Please select a country.";
    // }

    // if (!formData.state) {
    //   newErrors.state = "Please select a state.";
    // }

    // if (!formData.city) {
    //   newErrors.city = "Please select a city.";
    // }

    if (
      !formData.pin_code ||
      formData.pin_code.length !== 6 ||
      !/^\d{6}$/.test(formData.pin_code)
    ) {
      newErrors.pin_code = "Please enter a valid 6-digit pin code.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingUser) {
      if (editingUser.user_id && editingUser) {
        dispatch(
          updateUser({ userId: editingUser.user_id, data: editingUser })
        );
      } else {
        console.error("Error: user_id or editingUser is undefined");
      }
      setEditingUser(null);
    } else {
      if (validateForm(newUser)) {
        dispatch(registerUser(newUser));
        setNewUser({
          user_code: null,
          name: "",
          email: "",
          phone_number: "",
          password: "",
          user_type: "",
          membership_id: null,
          usergroup_id: null,
          referrer_id: null,
          city: "",
          county: "",
          state: "",
          country: "",
          pin_code: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingUser(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading users...");
    }
    if (error) {
      console.error("Error fetching users:", error);
    }
    if (users.length > 0) {
      console.log("Fetched users:", users);
    }
  }, [loading, error, users]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="user-list overflow-auto ">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New User
      </button>

      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                User Id
              </th>
              <th className="px-4 py-2 whitespace-nowrap">User Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Name</th>
              <th className="px-4 py-2 whitespace-nowrap">Email</th>
              <th className="px-4 py-2 whitespace-nowrap">Password</th>
              <th className="px-4 py-2 whitespace-nowrap">Phone</th>
              <th className="px-4 py-2 whitespace-nowrap">User Type</th>
              <th className="px-4 py-2 whitespace-nowrap">Membership ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Usergroup ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Referrer ID</th>
              <th className="px-4 py-2 whitespace-nowrap">City</th>
              <th className="px-4 py-2 whitespace-nowrap">County</th>
              <th className="px-4 py-2 whitespace-nowrap">State</th>
              <th className="px-4 py-2 whitespace-nowrap">Country</th>
              <th className="px-4 py-2 whitespace-nowrap">Pin Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {user.user_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="user_code"
                        value={editingUser.user_code || null}
                        onChange={handleInputChange}
                        placeholder="User Code"
                      />
                    </div>
                  ) : (
                    user.user_code
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="name"
                        value={editingUser.name || ""}
                        onChange={handleInputChange}
                        placeholder="Name"
                      />
                    </div>
                  ) : (
                    user.name
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="email"
                        value={editingUser.email || ""}
                        onChange={handleInputChange}
                        placeholder="Email"
                      />
                    </div>
                  ) : (
                    user.email
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="password"
                        name="password"
                        value={editingUser.password || ""}
                        onChange={handleInputChange}
                        placeholder="Password"
                      />
                    </div>
                  ) : (
                    user.password
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="phone_number"
                        value={editingUser.phone_number || ""}
                        onChange={handleInputChange}
                        placeholder="Phone"
                      />
                    </div>
                  ) : (
                    user.phone_number
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="user_type"
                        value={editingUser.user_type || ""}
                        onChange={handleInputChange}
                        placeholder="User Type"
                      />
                    </div>
                  ) : (
                    user.user_type
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="membership_id"
                        value={editingUser.membership_id || null}
                        onChange={handleInputChange}
                        placeholder="Membership ID"
                      />
                    </div>
                  ) : (
                    user.membership_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="usergroup_id"
                        value={editingUser.usergroup_id || null}
                        onChange={handleInputChange}
                        placeholder="Usergroup ID"
                      />
                    </div>
                  ) : (
                    user.usergroup_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="referrer_id"
                        value={editingUser.referrer_id || null}
                        onChange={handleInputChange}
                        placeholder="Referrer ID"
                      />
                    </div>
                  ) : (
                    user.referrer_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="city"
                        value={editingUser.city || ""}
                        onChange={handleInputChange}
                        placeholder="City"
                      />
                    </div>
                  ) : (
                    user.city
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="county"
                        value={editingUser.county || ""}
                        onChange={handleInputChange}
                        placeholder="County"
                      />
                    </div>
                  ) : (
                    user.county
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="state"
                        value={editingUser.state || ""}
                        onChange={handleInputChange}
                        placeholder="State"
                      />
                    </div>
                  ) : (
                    user.state
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="country"
                        value={editingUser.country || ""}
                        onChange={handleInputChange}
                        placeholder="Country"
                      />
                    </div>
                  ) : (
                    user.country
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="pin_code"
                        value={editingUser.pin_code || ""}
                        onChange={handleInputChange}
                        placeholder="Pin Code"
                      />
                    </div>
                  ) : (
                    user.pin_code
                  )}
                </td>
                <td className="px-4 py-2 flex-wrap ">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingUser.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    user.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingUser && editingUser.user_id === user.user_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white my-1 px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(user)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                        onClick={() => handleDelete(user.user_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}

            {showModal && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30  ">
                <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl  ">
                  <h2 className="text-2xl font-semibold mb-4">
                    Register New User
                  </h2>
                  <form className="grid grid-cols-5 gap-4 ">
                    <div className="input-group">
                      <label htmlFor="user_code">User Code</label>
                      <input
                        type="text"
                        name="user_code"
                        // value={newUser.user_code || null}
                        value={
                          (editingUser
                            ? editingUser.user_code
                            : newUser.user_code) || null
                        }
                        onChange={handleInputChange}
                        placeholder="User Code"
                        className="w-full p-2 mb-4 border rounded"
                      />
                      {/* {errors.user_code && (
                  <p className="text-red-500">{errors.user_code}</p>
                )} */}
                    </div>
                    <div className="input-group">
                      <label htmlFor="name">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        // value={newUser.name || ""}
                        value={
                          (editingUser ? editingUser.name : newUser.name) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="Name"
                        className="w-full p-2 mb-4 border rounded"
                      />
                      {errors.name && (
                        <p className="text-red-500">{errors.name}</p>
                      )}
                    </div>
                    <div className="input-group">
                      <label htmlFor="email">
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        // value={newUser.email || ""}
                        value={
                          (editingUser ? editingUser.email : newUser.email) ||
                          ""
                        }
                        onChange={handleInputChange}
                        placeholder="Email"
                        className="w-full p-2 mb-4 border rounded"
                      />
                      {errors.email && (
                        <p className="text-red-500">{errors.email}</p>
                      )}
                    </div>
                    <div className="input-group">
                      <label htmlFor="password">
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        // value={newUser.password || ""}
                        value={
                          (editingUser
                            ? editingUser.password
                            : newUser.password) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="Password"
                        className="w-full p-2 mb-4 border rounded"
                      />
                      {errors.password && (
                        <p className="text-red-500">{errors.password}</p>
                      )}
                    </div>
                    <div className="input-group">
                      <label htmlFor="phone_number">
                        Phone <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="phone_number"
                        // value={newUser.phone_number || ""}
                        value={
                          (editingUser
                            ? editingUser.phone_number
                            : newUser.phone_number) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="Phone Number"
                        className="w-full p-2 mb-4 border rounded"
                      />
                      {errors.phone_number && (
                        <p className="text-red-500">{errors.phone_number}</p>
                      )}
                    </div>
                    <div className="input-group">
                      <label htmlFor="user_type">User Type</label>
                      <input
                        type="text"
                        name="user_type"
                        // value={newUser.user_type || ""}
                        value={
                          (editingUser
                            ? editingUser.user_type
                            : newUser.user_type) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="User Type"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="membership_id">Membership ID</label>
                      <input
                        type="text"
                        name="membership_id"
                        // value={newUser.membership_id || null}
                        value={
                          (editingUser
                            ? editingUser.membership_id
                            : newUser.membership_id) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="Membership ID"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="usergroup_id">Usergroup ID</label>
                      <input
                        type="text"
                        name="usergroup_id"
                        // value={newUser.usergroup_id || null}
                        value={
                          (editingUser
                            ? editingUser.usergroup_id
                            : newUser.usergroup_id) || null
                        }
                        onChange={handleInputChange}
                        placeholder="Usergroup ID"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="referrer_id">Referrer ID</label>
                      <input
                        type="text"
                        name="referrer_id"
                        // value={newUser.referrer_id || null}
                        value={
                          (editingUser
                            ? editingUser.referrer_id
                            : newUser.referrer_id) || null
                        }
                        onChange={handleInputChange}
                        placeholder="Referrer ID"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        name="city"
                        // value={newUser.city || ""}
                        value={
                          (editingUser ? editingUser.city : newUser.city) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="City"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="county">County</label>
                      <input
                        type="text"
                        name="county"
                        // value={newUser.county || ""}
                        value={
                          (editingUser ? editingUser.county : newUser.county) ||
                          ""
                        }
                        onChange={handleInputChange}
                        placeholder="County"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="state">State</label>
                      <input
                        type="text"
                        name="state"
                        // value={newUser.state || ""}
                        value={
                          (editingUser ? editingUser.state : newUser.state) ||
                          ""
                        }
                        onChange={handleInputChange}
                        placeholder="State"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="country">Country</label>
                      <input
                        type="text"
                        name="country"
                        // value={newUser.country || ""}
                        value={
                          (editingUser
                            ? editingUser.country
                            : newUser.country) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="Country"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="pin_code">
                        Pin Code <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="pin_code"
                        // value={newUser.pin_code || ""}
                        value={
                          (editingUser
                            ? editingUser.pin_code
                            : newUser.pin_code) || ""
                        }
                        onChange={handleInputChange}
                        placeholder="Pin Code"
                        className="w-full p-2 mb-4 border rounded"
                      />
                      {errors.pin_code && (
                        <p className="text-red-500">{errors.pin_code}</p>
                      )}
                    </div>
                    <div className="input-group ">
                      <label htmlFor="remark">Remark</label>
                      <textarea
                        name="remark"
                        value={
                          (editingUser ? editingUser.remark : newUser.remark) ||
                          ""
                        }
                        onChange={handleInputChange}
                        placeholder="Remark"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                    <div className="col-span-5 text-center">
                      <button
                        type="button"
                        className="bg-green-500 text-white px-4 py-2  rounded "
                        onClick={handleSave}
                      >
                        {editingUser ? "Update Event" : "Save"}
                      </button>
                      <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2  rounded ml-2"
                        onClick={toggleModal}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
