// prutl-frontend-npm6node14/src/components/organizationManagement/OrganizationList.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllOrganizations,
  createNewOrganization,
  updateOrganization,
  deleteOrganization,
} from '../../redux/slices/organizationSlice.js';

const OrganizationList = () => {
  const dispatch = useDispatch();
  const { organizations, loading, error } = useSelector((state) => state.organizations);
  const [editingOrg, setEditingOrg] = useState(null);
  const [newOrg, setNewOrg] = useState({
    org_code: '',
    org_name: '',
    org_type: '',
    address: '',
    phone_number: '',
    contact_person: '',
    city: '',
    county: '',
    state: '',
    country: '',
    pin_code: '',
    remark: '',
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllOrganizations());
  }, [dispatch]);

  const handleDelete = (orgId) => {
    dispatch(deleteOrganization(orgId));
  };

  const handleEdit = (organization) => {
    setEditingOrg({ ...organization });
    // setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingOrg) {
      setEditingOrg({ ...editingOrg, [name]: value });
    } else {
      setNewOrg({ ...newOrg, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.org_code) newErrors.org_code = 'Organization code is required';
    if (!formData.org_name) newErrors.org_name = 'Organization name is required';
    // if (formData.phone_number && !/^\+?[0-9\- ]+$/.test(formData.phone_number)) newErrors.phone_number = 'Invalid phone number format';
    // if (formData.pin_code && !/^\d{5}(-\d{4})?$/.test(formData.pin_code)) newErrors.pin_code = 'Invalid postal code format';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingOrg) {
      if (editingOrg.org_id && editingOrg) {
        dispatch(updateOrganization({ orgId: editingOrg.org_id, data: editingOrg }));
       
      }
     else {
        console.error("Error: orgId or editingOrg is undefined");
     }
     setEditingOrg(null);
    }
    else{
      if (validateForm(newOrg)) {
        dispatch(createNewOrganization(newOrg));
        setNewOrg({
          org_code: '',
          org_name: '',
          org_type: '',
          address: '',
          phone_number: '',
          contact_person: '',
          city: '',
          county: '',
          state: '',
          country: '',
          pin_code: '',
          remark: '',
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingOrg(null);
   
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading organizations...");
    }
    if (error) {
      console.error("Error fetching organizations:", error);
    }
    if (organizations.length > 0) {
      console.log("Fetched organizations:", organizations);
    }
  }, [loading, error, organizations]);


  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="organization-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick= {toggleModal}
      >
        Create New Organization
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        
      <table className="min-w-full divide-y divide-gray-200">
      <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
      <tr>
      <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
      Organization ID</th>
    <th className="px-4 py-2 whitespace-nowrap">Organization Code</th>
    <th className="px-4 py-2 whitespace-nowrap">Organization Name</th>
    <th className="px-4 py-2 whitespace-nowrap">Organization Type</th>
    <th className="px-4 py-2 whitespace-nowrap">Address</th>
    <th className="px-4 py-2 whitespace-nowrap">Phone Number</th>
    <th className="px-4 py-2 whitespace-nowrap">Contact Person</th>
    <th className="px-4 py-2 whitespace-nowrap">City</th>
    <th className="px-4 py-2 whitespace-nowrap">County</th>
    <th className="px-4 py-2 whitespace-nowrap">State</th>
    <th className="px-4 py-2 whitespace-nowrap">Country</th>
    <th className="px-4 py-2 whitespace-nowrap">Pin Code</th>
    <th className="px-4 py-2 whitespace-nowrap">Remark</th>
    <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">Actions</th>
    </tr>
      </thead>
    <tbody>
  {organizations.map((organization) => (
    <tr key={organization.org_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{organization.org_id}</td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="org_code"
              value={editingOrg.org_code || ""}
              onChange={handleInputChange}
              placeholder="Organization Code"
            />
          </div>
        ) : (
          organization.org_code
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="org_name"
              value={editingOrg.org_name || ""}
              onChange={handleInputChange}
              placeholder="Organization Name"
            />
          </div>
        ) : (
          organization.org_name
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="org_type"
              value={editingOrg.org_type || ""}
              onChange={handleInputChange}
              placeholder="Organization Type"
            />
          </div>
        ) : (
          organization.org_type
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="address"
              value={editingOrg.address || ""}
              onChange={handleInputChange}
              placeholder="Address"
            />
          </div>
        ) : (
          organization.address
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="phone_number"
              value={editingOrg.phone_number || ""}
              onChange={handleInputChange}
              placeholder="Phone Number"
            />
          </div>
        ) : (
          organization.phone_number
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="contact_person"
              value={editingOrg.contact_person || ""}
              onChange={handleInputChange}
              placeholder="Contact Person"
            />
          </div>
        ) : (
          organization.contact_person
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="city"
              value={editingOrg.city || ""}
              onChange={handleInputChange}
              placeholder="City"
            />
          </div>
        ) : (
          organization.city
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="county"
              value={editingOrg.county || ""}
              onChange={handleInputChange}
              placeholder="County"
            />
          </div>
        ) : (
          organization.county
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="state"
              value={editingOrg.state || ""}
              onChange={handleInputChange}
              placeholder="State"
            />
          </div>
        ) : (
          organization.state
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="country"
              value={editingOrg.country || ""}
              onChange={handleInputChange}
              placeholder="Country"
            />
          </div>
        ) : (
          organization.country
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="pin_code"
              value={editingOrg.pin_code || ""}
              onChange={handleInputChange}
              placeholder="Pin Code"
            />
          </div>
        ) : (
          organization.pin_code
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        {editingOrg && editingOrg.org_id === organization.org_id ? (
          <div className="input-group">
            <input
              type="text"
              name="remark"
              value={editingOrg.remark || ""}
              onChange={handleInputChange}
              placeholder="Remark"
            />
          </div>
        ) : (
          organization.remark
        )}
      </td>
      <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
      {editingOrg && editingOrg.org_id === organization.org_id ? (
          <>
            <button
              className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="bg-gray-500 text-white px-3 py-1 rounded"
              onClick={handleUndo}
            >
              Undo
            </button>
          </>
        ) : (
          <>
            <button
              className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
              onClick={() => handleEdit(organization)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-2 py-1 my-1 rounded"
              onClick={() => handleDelete(organization.org_id)}
            >
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  ))}
    </tbody>
      </table>
        </div>

      {showModal && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30 ">
      <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl  ">
        <h2 className="text-2xl font-semibold mb-4">
       Register New Organization
       </h2>
       <form className="grid grid-cols-4 gap-4">
  <div className="input-group">
    <label htmlFor="org_code">
      Organization Code <span style={{ color: "red" }}>*</span>
    </label>
    <input
      type="text"
      name="org_code"
      value={newOrg.org_code || ""}
      onChange={handleInputChange}
      placeholder="Organization Code"
      className="w-full p-2 mb-4 border rounded"
    />
    {errors.org_code && (
      <p className="text-red-500">{errors.org_code}</p>
    )}
  </div>
  <div className="input-group">
    <label htmlFor="org_name">
      Organization Name <span style={{ color: "red" }}>*</span>
    </label>
    <input
      type="text"
      name="org_name"
      value={newOrg.org_name || ""}
      onChange={handleInputChange}
      placeholder="Organization Name"
      className="w-full p-2 mb-4 border rounded"
    />
    {errors.org_name && (
      <p className="text-red-500">{errors.org_name}</p>
    )}
  </div>
  <div className="input-group">
    <label htmlFor="org_type">Organization Type</label>
    <input
      type="text"
      name="org_type"
      value={newOrg.org_type || ""}
      onChange={handleInputChange}
      placeholder="Organization Type"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="address">Address</label>
    <input
      type="text"
      name="address"
      value={newOrg.address || ""}
      onChange={handleInputChange}
      placeholder="Address"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="phone_number">Phone Number</label>
    <input
      type="text"
      name="phone_number"
      value={newOrg.phone_number || ""}
      onChange={handleInputChange}
      placeholder="Phone Number"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="contact_person">Contact Person</label>
    <input
      type="text"
      name="contact_person"
      value={newOrg.contact_person || ""}
      onChange={handleInputChange}
      placeholder="Contact Person"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="city">City</label>
    <input
      type="text"
      name="city"
      value={newOrg.city || ""}
      onChange={handleInputChange}
      placeholder="City"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="county">County</label>
    <input
      type="text"
      name="county"
      value={newOrg.county || ""}
      onChange={handleInputChange}
      placeholder="County"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="state">State</label>
    <input
      type="text"
      name="state"
      value={newOrg.state || ""}
      onChange={handleInputChange}
      placeholder="State"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="country">Country</label>
    <input
      type="text"
      name="country"
      value={newOrg.country || ""}
      onChange={handleInputChange}
      placeholder="Country"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group">
    <label htmlFor="pin_code">Pin Code</label>
    <input
      type="text"
      name="pin_code"
      value={newOrg.pin_code || ""}
      onChange={handleInputChange}
      placeholder="Pin Code"
      className="w-full p-2 mb-4 border rounded"
    />
  </div>
  <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                <textarea
                  name="remark"
                  value={
                    (editingOrg ? editingOrg.remark : newOrg.remark) || ""
                  }
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
              </div>
  <div className="col-span-4 text-center">
    <button
      type="button"
      className="bg-green-500 text-white px-5 py-2 rounded mr-2"
      onClick={handleSave}
    >
      Save
    </button>
    <button
      type="button"
      className="bg-gray-500 text-white px-4 py-2 rounded"
      onClick={toggleModal}
    >
      Close
    </button>
  </div>
</form>

          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizationList;
