//pcombinator-frontend-npm6node14/src/pages/Signup.jsx
import React from 'react';
import SignupForm from '../components/auth/SignupForm';
// import SignupForm from '../components/SignupForm';

const Signup = () => {
  return (
    <div className="container">
      <SignupForm />
    </div>
  );
};

export default Signup;
