// prutl-frontend-npm6node14/src/components/scoreManagement/ScoreList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllScores,
  createNewScore,
  updateScore,
  deleteScore,
} from "../../redux/slices/scoreSlice.js";

const ScoreList = () => {
  const dispatch = useDispatch();
  const { scores, loading, error } = useSelector((state) => state.scores);
  const [editingScore, setEditingScore] = useState(null);
  const [newScore, setNewScore] = useState({
    user_id: "",
    team_id: "",
    score_value: "",
    assessment_date: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllScores());
  }, [dispatch]);

  const handleDelete = (scoreId) => {
    dispatch(deleteScore(scoreId));
  };

  const handleEdit = (score) => {
    setEditingScore({ ...score });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingScore) {
      setEditingScore({ ...editingScore, [name]: value });
    } else {
      setNewScore({ ...newScore, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.user_id) newErrors.user_id = "User ID is required";
    if (!formData.team_id) newErrors.team_id = "Team ID is required";
    if (!formData.score_value)
      newErrors.score_value = "Score Value is required";
    if (!formData.assessment_date)
      newErrors.assessment_date = "Assessment Date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingScore) {
      if (editingScore.score_id && editingScore) {
        dispatch(
          updateScore({ scoreId: editingScore.score_id, data: editingScore })
        );
      } else {
        console.error("Error: scoreId or editingScore is undefined");
      }
      setEditingScore(null);
    } else {
      if (validateForm(newScore)) {
        dispatch(createNewScore(newScore));
        setNewScore({
          user_id: "",
          team_id: "",
          score_value: "",
          assessment_date: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingScore(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading scores...");
    }
    if (error) {
      console.error("Error fetching scores:", error);
    }
    if (scores.length > 0) {
      console.log("Fetched scores:", scores);
    }
  }, [loading, error, scores]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="score-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Score
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Score ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">User ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Team ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Score Value</th>
              <th className="px-4 py-2 whitespace-nowrap">Assessment Date</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score) => (
              <tr key={score.score_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {score.score_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore && editingScore.score_id === score.score_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="user_id"
                        value={editingScore.user_id || ""}
                        onChange={handleInputChange}
                        placeholder="User ID"
                      />
                    </div>
                  ) : (
                    score.user_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore && editingScore.score_id === score.score_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="team_id"
                        value={editingScore.team_id || ""}
                        onChange={handleInputChange}
                        placeholder="Team ID"
                      />
                    </div>
                  ) : (
                    score.team_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore && editingScore.score_id === score.score_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="score_value"
                        value={editingScore.score_value || ""}
                        onChange={handleInputChange}
                        placeholder="Score Value"
                      />
                    </div>
                  ) : (
                    score.score_value
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore && editingScore.score_id === score.score_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="assessment_date"
                        value={editingScore.assessment_date || ""}
                        onChange={handleInputChange}
                        placeholder="Assessment Date"
                      />
                    </div>
                  ) : (
                    new Date(score.assessment_date).toLocaleDateString()
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore && editingScore.score_id === score.score_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingScore.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    score.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0  tableHeadEditDelete">
                  {editingScore && editingScore.score_id === score.score_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(score)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                        onClick={() => handleDelete(score.score_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">Register New Score</h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="user_id">
                  User ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="user_id"
                  value={newScore.user_id || ""}
                  onChange={handleInputChange}
                  placeholder="User ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.user_id && (
                  <p className="text-red-500">{errors.user_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="team_id">
                  Team ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="team_id"
                  value={newScore.team_id || ""}
                  onChange={handleInputChange}
                  placeholder="Team ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.team_id && (
                  <p className="text-red-500">{errors.team_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="score_value">
                  Score Value <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="score_value"
                  value={newScore.score_value || ""}
                  onChange={handleInputChange}
                  placeholder="Score Value"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.score_value && (
                  <p className="text-red-500">{errors.score_value}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="assessment_date">
                  Assessment Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="assessment_date"
                  value={newScore.assessment_date || ""}
                  onChange={handleInputChange}
                  placeholder="Assessment Date"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.assessment_date && (
                  <p className="text-red-500">{errors.assessment_date}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>

                <textarea
                  name="remark"
                  value={newScore.remark || ""}
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default ScoreList;
