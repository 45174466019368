//prutl-frontend-npm6node14/src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login, register } from "../../utils/api";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password, keepSignedIn }, { rejectWithValue }) => {
    try {
      const response = await login(email, password,keepSignedIn);
    
      if (keepSignedIn) {
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("user_id", JSON.stringify(response.user.user_id));
        localStorage.setItem("token", response.token);
      } else {
        sessionStorage.setItem("user", JSON.stringify(response.user));
        sessionStorage.setItem(
          "user_id",
          JSON.stringify(response.user.user_id)
        );
        sessionStorage.setItem("token", response.token);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await register(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user:
      JSON.parse(localStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user")) ||
      null,
    user_id:
      localStorage.getItem("user_id") ||
      sessionStorage.getItem("user_id") ||
      null,
    token:
      localStorage.getItem("token") || sessionStorage.getItem("token") || null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.user = null;
      state.token = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUser_Id: (state, action) => {
      state.user_id = action.payload.user.user_id;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.user_id = action.payload.user.user_id;
        state.loggedinuser = action.payload.user;
        state.token = action.payload.token;
      
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, setUser, setToken, setUser_Id } = authSlice.actions;
export default authSlice.reducer;
