//prutl-frontend-npm6node14/src/utils/api.js
import { REACT_APP_API_URL } from './endpoint';

// const getToken = () => localStorage.getItem('token');
const getToken = () => {
  return sessionStorage.getItem('token') || localStorage.getItem('token');
};

const getHeaders = () => {
  const token = getToken();
  return {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : '',
  };
};

export const login = async (email, password, keepSignedIn) => {
  const response = await fetch(`${REACT_APP_API_URL}/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password, keepSignedIn }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Login failed');
   
  }

  return response.json();
};

export const register = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/auth/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Registration failed');
  }

  return response.json();
};

export const fetchAllUsers = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/users`, {
    method: 'GET',
    headers: getHeaders(),
  });
  // if (!response.ok) {
  //   throw new Error('Fetching users failed');
  // }
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching users failed');
   
  }

  return response.json(); // Return the array of users
};

export const fetchUserById = async (userId) => {
  const response = await fetch(`${REACT_APP_API_URL}/users/${userId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching user failed');
  }
  console.log('response.json for fetchUserById in api.js page +++',response)

  return response.json();

};

export const updateUserById = async (userId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/users/${userId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating user failed');
  }

  return response.json();
};

export const deleteUserById = async (userId) => {
  const response = await fetch(`${REACT_APP_API_URL}/users/${userId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting user failed');
  }
};

  // Membership APIs


  export const createMembership = async (data) => {
    const response = await fetch(`${REACT_APP_API_URL}/memberships`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Creating membership failed');
    }

    return response.json();
  };

  export const fetchAllMemberships = async () => {
    const response = await fetch(`${REACT_APP_API_URL}/memberships`, {
      method: 'GET',
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Fetching memberships failed');
    }

    return response.json();
  };

  export const fetchMembershipById = async (membershipId) => {
    const response = await fetch(`${REACT_APP_API_URL}/memberships/${membershipId}`, {
      method: 'GET',
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Fetching membership failed');
    }

    return response.json();
  };

  export const updateMembershipById = async (membershipId, data) => {
    const response = await fetch(`${REACT_APP_API_URL}/memberships/${membershipId}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Updating membership failed');
    }

    return response.json();
  };

  export const deleteMembershipById = async (membershipId) => {
    const response = await fetch(`${REACT_APP_API_URL}/memberships/${membershipId}`, {
      method: 'DELETE',
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Deleting membership failed');
    }
  };


// Organization APIs

export const createOrganization = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/organizations`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating organization failed');
  }

  return response.json();
};

export const fetchAllOrganizations = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/organizations`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching organizations failed');
  }

  return response.json();
};

export const fetchOrganizationById = async (orgId) => {
  const response = await fetch(`${REACT_APP_API_URL}/organizations/${orgId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching organization failed');
  }

  return response.json();
};

export const updateOrganizationById = async (orgId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/organizations/${orgId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating organization failed');
  }

  return response.json();
};

export const deleteOrganizationById = async (orgId) => {
  const response = await fetch(`${REACT_APP_API_URL}/organizations/${orgId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting organization failed');
  }
};

// Event APIs

export const createEvent = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/events`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating event failed');
  }

  return response.json();
};

export const fetchAllEvents = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/events`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching events failed');
  }

  return response.json();
};

export const fetchEventById = async (eventId) => {
  const response = await fetch(`${REACT_APP_API_URL}/events/${eventId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching event failed');
  }

  return response.json();
};

export const updateEventById = async (eventId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/events/${eventId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating event failed');
  }

  return response.json();
};

export const deleteEventById = async (eventId) => {
  const response = await fetch(`${REACT_APP_API_URL}/events/${eventId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting event failed');
  }
};

// Competition APIs

export const createCompetition = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/competitions`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating competition failed');
  }

  return response.json();
};

export const fetchAllCompetitions = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/competitions`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching competitions failed');
  }

  return response.json();
};

export const fetchCompetitionById = async (competitionId) => {
  const response = await fetch(`${REACT_APP_API_URL}/competitions/${competitionId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching competition failed');
  }

  return response.json();
};

export const updateCompetitionById = async (competitionId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/competitions/${competitionId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating competition failed');
  }

  return response.json();
};

export const deleteCompetitionById = async (competitionId) => {
  const response = await fetch(`${REACT_APP_API_URL}/competitions/${competitionId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting competition failed');
  }
};

// Event Schedule APIs

export const createEventSchedule = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-schedules`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating event schedule failed');
  }

  return response.json();
};

export const fetchAllEventSchedules = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/event-schedules`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching event schedules failed');
  }

  return response.json();
};

export const fetchEventScheduleById = async (scheduleId) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-schedules/${scheduleId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching event schedule failed');
  }

  return response.json();
};

export const updateEventScheduleById = async (scheduleId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-schedules/${scheduleId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating event schedule failed');
  }

  return response.json();
};

export const deleteEventScheduleById = async (scheduleId) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-schedules/${scheduleId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting event schedule failed');
  }
};

// Stream APIs

export const createStream = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/streams`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating stream failed');
  }

  return response.json();
};

export const fetchAllStreams = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/streams`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching streams failed');
  }

  return response.json();
};

export const fetchStreamById = async (streamId) => {
  const response = await fetch(`${REACT_APP_API_URL}/streams/${streamId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching stream failed');
  }

  return response.json();
};

export const updateStreamById = async (streamId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/streams/${streamId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating stream failed');
  }

  return response.json();
};

export const deleteStreamById = async (streamId) => {
  const response = await fetch(`${REACT_APP_API_URL}/streams/${streamId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting stream failed');
  }
};

// Participant APIs

export const createParticipant = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/participants`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating participant failed');
  }

  return response.json();
};

export const fetchAllParticipants = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/participants`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching participants failed');
  }

  return response.json();
};

export const fetchParticipantById = async (participantId) => {
  const response = await fetch(`${REACT_APP_API_URL}/participants/${participantId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching participant failed');
  }

  return response.json();
};

export const updateParticipantById = async (participantId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/participants/${participantId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating participant failed');
  }

  return response.json();
};

export const deleteParticipantById = async (participantId) => {
  const response = await fetch(`${REACT_APP_API_URL}/participants/${participantId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting participant failed');
  }
};

// Sponsors APIs

export const createSponsor = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsors`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating sponsor failed');
  }

  return response.json();
};

export const fetchAllSponsors = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsors`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching sponsors failed');
  }

  return response.json();
};

export const fetchSponsorById = async (sponsorId) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsors/${sponsorId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching sponsor failed');
  }

  return response.json();
};

export const updateSponsorById = async (sponsorId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsors/${sponsorId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating sponsor failed');
  }

  return response.json();
};

export const deleteSponsorById = async (sponsorId) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsors/${sponsorId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting sponsor failed');
  }
};

// Teams APIs

export const createTeam = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/teams`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating team failed');
  }

  return response.json();
};

export const fetchAllTeams = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/teams`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching teams failed');
  }

  return response.json();
};

export const fetchTeamById = async (teamId) => {
  const response = await fetch(`${REACT_APP_API_URL}/teams/${teamId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching team failed');
  }

  return response.json();
};

export const updateTeamById = async (teamId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/teams/${teamId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating team failed');
  }

  return response.json();
};

export const deleteTeamById = async (teamId) => {
  const response = await fetch(`${REACT_APP_API_URL}/teams/${teamId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting team failed');
  }
};

// Committees APIs

export const createCommittee = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/committees`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating committee failed');
  }

  return response.json();
};

export const fetchAllCommittees = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/committees`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching committees failed');
  }

  return response.json();
};

export const fetchCommitteeById = async (committeeId) => {
  const response = await fetch(`${REACT_APP_API_URL}/committees/${committeeId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching committee failed');
  }

  return response.json();
};

export const updateCommitteeById = async (committeeId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/committees/${committeeId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating committee failed');
  }

  return response.json();
};

export const deleteCommitteeById = async (committeeId) => {
  const response = await fetch(`${REACT_APP_API_URL}/committees/${committeeId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting committee failed');
  }
};

// CommitteeMembers APIs

export const createCommitteeMember = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/committee-members`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating committee member failed');
  }

  return response.json();
};

export const fetchAllCommitteeMembers = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/committee-members`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching committee members failed');
  }

  return response.json();
};

export const fetchCommitteeMemberById = async (committeeMemberId) => {
  const response = await fetch(`${REACT_APP_API_URL}/committee-members/${committeeMemberId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching committee member failed');
  }

  return response.json();
};

export const updateCommitteeMemberById = async (committeeMemberId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/committee-members/${committeeMemberId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating committee member failed');
  }

  return response.json();
};

export const deleteCommitteeMemberById = async (committeeMemberId) => {
  const response = await fetch(`${REACT_APP_API_URL}/committee-members/${committeeMemberId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting committee member failed');
  }
};

// Awards APIs

export const createAward = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/awards`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating award failed');
  }

  return response.json();
};

export const fetchAllAwards = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/awards`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching awards failed');
  }

  return response.json();
};

export const fetchAwardById = async (awardId) => {
  const response = await fetch(`${REACT_APP_API_URL}/awards/${awardId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching award failed');
  }

  return response.json();
};

export const updateAwardById = async (awardId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/awards/${awardId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating award failed');
  }

  return response.json();
};

export const deleteAwardById = async (awardId) => {
  const response = await fetch(`${REACT_APP_API_URL}/awards/${awardId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting award failed');
  }
};

// Scores APIs

export const createScore = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/scores`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating score failed');
  }

  return response.json();
};

export const fetchAllScores = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/scores`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching scores failed');
  }

  return response.json();
};

export const fetchScoreById = async (scoreId) => {
  const response = await fetch(`${REACT_APP_API_URL}/scores/${scoreId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching score failed');
  }

  return response.json();
};

export const updateScoreById = async (scoreId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/scores/${scoreId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating score failed');
  }

  return response.json();
};

export const deleteScoreById = async (scoreId) => {
  const response = await fetch(`${REACT_APP_API_URL}/scores/${scoreId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting score failed');
  }
};

// Sponsorships APIs

export const createSponsorship = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsorships`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating sponsorship failed');
  }

  return response.json();
};

export const fetchAllSponsorships = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsorships`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching sponsorships failed');
  }

  return response.json();
};

export const fetchSponsorshipById = async (sponsorshipId) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsorships/${sponsorshipId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching sponsorship failed');
  }

  return response.json();
};

export const updateSponsorshipById = async (sponsorshipId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsorships/${sponsorshipId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating sponsorship failed');
  }

  return response.json();
};

export const deleteSponsorshipById = async (sponsorshipId) => {
  const response = await fetch(`${REACT_APP_API_URL}/sponsorships/${sponsorshipId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting sponsorship failed');
  }
};

// passionFrameworkDimensions APIs

export const createPassionFrameworkDimension = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/passion-framework-dimensions`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Passion Framework Dimension failed');
  }

  return response.json();
};

export const fetchAllPassionFrameworkDimensions = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/passion-framework-dimensions`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Passion Framework Dimensions failed');
  }

  return response.json();
};

export const fetchPassionFrameworkDimensionById = async (dimensionId) => {
  const response = await fetch(`${REACT_APP_API_URL}/passion-framework-dimensions/${dimensionId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Passion Framework Dimension failed');
  }

  return response.json();
};

export const updatePassionFrameworkDimensionById = async (dimensionId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/passion-framework-dimensions/${dimensionId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Passion Framework Dimension failed');
  }

  return response.json();
};

export const deletePassionFrameworkDimensionById = async (dimensionId) => {
  const response = await fetch(`${REACT_APP_API_URL}/passion-framework-dimensions/${dimensionId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Passion Framework Dimension failed');
  }
};

// DimensionScores APIs

export const createDimensionScore = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/dimension-scores`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Dimension Score failed');
  }

  return response.json();
};

export const fetchAllDimensionScores = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/dimension-scores`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Dimension Scores failed');
  }

  return response.json();
};

export const fetchDimensionScoreById = async (dimensionScoreId) => {
  const response = await fetch(`${REACT_APP_API_URL}/dimension-scores/${dimensionScoreId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Dimension Score failed');
  }

  return response.json();
};

export const updateDimensionScoreById = async (dimensionScoreId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/dimension-scores/${dimensionScoreId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Dimension Score failed');
  }

  return response.json();
};

export const deleteDimensionScoreById = async (dimensionScoreId) => {
  const response = await fetch(`${REACT_APP_API_URL}/dimension-scores/${dimensionScoreId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Dimension Score failed');
  }
};

// UserGroups APIs

export const createUserGroup = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/user-groups`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating User Group failed');
  }

  return response.json();
};

export const fetchAllUserGroups = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/user-groups`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching User Groups failed');
  }

  return response.json();
};

export const fetchUserGroupById = async (userGroupId) => {
  const response = await fetch(`${REACT_APP_API_URL}/user-groups/${userGroupId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching User Group failed');
  }

  return response.json();
};

export const updateUserGroupById = async (userGroupId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/user-groups/${userGroupId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating User Group failed');
  }

  return response.json();
};

export const deleteUserGroupById = async (userGroupId) => {
  const response = await fetch(`${REACT_APP_API_URL}/user-groups/${userGroupId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting User Group failed');
  }
};

// Families APIs

export const createFamily = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/families`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Family failed');
  }

  return response.json();
};

export const fetchAllFamilies = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/families`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Families failed');
  }

  return response.json();
};

export const fetchFamilyById = async (familyId) => {
  const response = await fetch(`${REACT_APP_API_URL}/families/${familyId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Family failed');
  }

  return response.json();
};

export const updateFamilyById = async (familyId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/families/${familyId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Family failed');
  }

  return response.json();
};

export const deleteFamilyById = async (familyId) => {
  const response = await fetch(`${REACT_APP_API_URL}/families/${familyId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Family failed');
  }
};

// Family Members APIs

export const createFamilyMember = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/family-members`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Family Member failed');
  }

  return response.json();
};

export const fetchAllFamilyMembers = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/family-members`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Family Members failed');
  }

  return response.json();
};

export const fetchFamilyMemberById = async (familyMemberId) => {
  const response = await fetch(`${REACT_APP_API_URL}/family-members/${familyMemberId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Family Member failed');
  }

  return response.json();
};

export const updateFamilyMemberById = async (familyMemberId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/family-members/${familyMemberId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Family Member failed');
  }

  return response.json();
};

export const deleteFamilyMemberById = async (familyMemberId) => {
  const response = await fetch(`${REACT_APP_API_URL}/family-members/${familyMemberId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Family Member failed');
  }
};

// AI Insights APIs

export const createAIInsight = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/ai-insights`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating AI Insight failed');
  }

  return response.json();
};

export const fetchAllAIInsights = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/ai-insights`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching AI Insights failed');
  }

  return response.json();
};

export const fetchAIInsightById = async (insightId) => {
  const response = await fetch(`${REACT_APP_API_URL}/ai-insights/${insightId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching AI Insight failed');
  }

  return response.json();
};

export const updateAIInsightById = async (insightId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/ai-insights/${insightId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating AI Insight failed');
  }

  return response.json();
};

export const deleteAIInsightById = async (insightId) => {
  const response = await fetch(`${REACT_APP_API_URL}/ai-insights/${insightId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting AI Insight failed');
  }
};

// Venues APIs

export const createVenue = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/venues`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Venue failed');
  }

  return response.json();
};

export const fetchAllVenues = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/venues`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Venues failed');
  }

  return response.json();
};

export const fetchVenueById = async (venueId) => {
  const response = await fetch(`${REACT_APP_API_URL}/venues/${venueId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Venue failed');
  }

  return response.json();
};

export const updateVenueById = async (venueId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/venues/${venueId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Venue failed');
  }

  return response.json();
};

export const deleteVenueById = async (venueId) => {
  const response = await fetch(`${REACT_APP_API_URL}/venues/${venueId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Venue failed');
  }
};

// Halls APIs

export const createHall = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/halls`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Hall failed');
  }

  return response.json();
};

export const fetchAllHalls = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/halls`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Halls failed');
  }

  return response.json();
};

export const fetchHallById = async (hallId) => {
  const response = await fetch(`${REACT_APP_API_URL}/halls/${hallId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Hall failed');
  }

  return response.json();
};

export const updateHallById = async (hallId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/halls/${hallId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Hall failed');
  }

  return response.json();
};

export const deleteHallById = async (hallId) => {
  const response = await fetch(`${REACT_APP_API_URL}/halls/${hallId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Hall failed');
  }
};

// Event Bookings APIs

export const createEventBooking = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-bookings`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Event Booking failed');
  }

  return response.json();
};

export const fetchAllEventBookings = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/event-bookings`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Event Bookings failed');
  }

  return response.json();
};

export const fetchEventBookingById = async (bookingId) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-bookings/${bookingId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Event Booking failed');
  }

  return response.json();
};

export const updateEventBookingById = async (bookingId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-bookings/${bookingId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Event Booking failed');
  }

  return response.json();
};

export const deleteEventBookingById = async (bookingId) => {
  const response = await fetch(`${REACT_APP_API_URL}/event-bookings/${bookingId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Event Booking failed');
  }
};

// Guest Services APIs

export const createGuestService = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/guest-services`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Guest Service failed');
  }

  return response.json();
};

export const fetchAllGuestServices = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/guest-services`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Guest Services failed');
  }

  return response.json();
};

export const fetchGuestServiceById = async (serviceId) => {
  const response = await fetch(`${REACT_APP_API_URL}/guest-services/${serviceId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Guest Service failed');
  }

  return response.json();
};

export const updateGuestServiceById = async (serviceId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/guest-services/${serviceId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Guest Service failed');
  }

  return response.json();
};

export const deleteGuestServiceById = async (serviceId) => {
  const response = await fetch(`${REACT_APP_API_URL}/guest-services/${serviceId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Guest Service failed');
  }
};

// Booking Services APIs

export const createBookingService = async (data) => {
  const response = await fetch(`${REACT_APP_API_URL}/booking-services`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Creating Booking Service failed');
  }

  return response.json();
};

export const fetchAllBookingServices = async () => {
  const response = await fetch(`${REACT_APP_API_URL}/booking-services`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Booking Services failed');
  }

  return response.json();
};

export const fetchBookingServiceById = async (serviceId) => {
  const response = await fetch(`${REACT_APP_API_URL}/booking-services/${serviceId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Fetching Booking Service failed');
  }

  return response.json();
};

export const updateBookingServiceById = async (serviceId, data) => {
  const response = await fetch(`${REACT_APP_API_URL}/booking-services/${serviceId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Updating Booking Service failed');
  }

  return response.json();
};

export const deleteBookingServiceById = async (serviceId) => {
  const response = await fetch(`${REACT_APP_API_URL}/booking-services/${serviceId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Deleting Booking Service failed');
  }
};
