// prutl-frontend-npm6node14/src/components/aiInsightManagement/AIInsightList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAIInsights,
  createNewAIInsight,
  updateAIInsight,
  deleteAIInsight,
} from "../../redux/slices/aiInsightSlice.js";

const AIInsightList = () => {
  const dispatch = useDispatch();
  const { aiInsights, loading, error } = useSelector(
    (state) => state.aiInsights
  );
  const [editingAIInsight, setEditingAIInsight] = useState(null);
  const [newAIInsight, setNewAIInsight] = useState({
    user_id: "",
    insight_type: "",
    insight_data: "",
    insight_date: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllAIInsights());
  }, [dispatch]);

  const handleDelete = (insightId) => {
    dispatch(deleteAIInsight(insightId));
  };

  const handleEdit = (aiInsight) => {
    setEditingAIInsight({ ...aiInsight });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingAIInsight) {
      setEditingAIInsight({ ...editingAIInsight, [name]: value });
    } else {
      setNewAIInsight({ ...newAIInsight, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.insight_type)
      newErrors.insight_type = "Insight Type is required";
    if (!formData.insight_data)
      newErrors.insight_data = "Insight Data is required";
    if (!formData.insight_date)
      newErrors.insight_date = "Insight Date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingAIInsight) {
      if (editingAIInsight.insight_id && editingAIInsight) {
        dispatch(
          updateAIInsight({
            insightId: editingAIInsight.insight_id,
            data: editingAIInsight,
          })
        );
      } else {
        console.error("Error: insightId or editingAIInsight is undefined");
      }
      setEditingAIInsight(null);
    } else {
      if (validateForm(newAIInsight)) {
        dispatch(createNewAIInsight(newAIInsight));
        setNewAIInsight({
          user_id: "",
          insight_type: "",
          insight_data: "",
          insight_date: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingAIInsight(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading AI insights...");
    }
    if (error) {
      console.error("Error fetching AI insights:", error);
    }
    if (aiInsights.length > 0) {
      console.log("Fetched AI insights:", aiInsights);
    }
  }, [loading, error, aiInsights]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="ai-insight-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New AI Insight
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete">
            <tr>
            <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
            Insight ID</th>
              <th className="px-4 py-2 whitespace-nowrap">User ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Insight Type</th>
              <th className="px-4 py-2 whitespace-nowrap">Insight Data</th>
              <th className="px-4 py-2 whitespace-nowrap">Insight Date</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {aiInsights.map((aiInsight) => (
              <tr key={aiInsight.insight_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{aiInsight.insight_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAIInsight &&
                  editingAIInsight.insight_id === aiInsight.insight_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="user_id"
                        value={editingAIInsight.user_id || ""}
                        onChange={handleInputChange}
                        placeholder="User ID"
                      />
                    </div>
                  ) : (
                    aiInsight.user_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAIInsight &&
                  editingAIInsight.insight_id === aiInsight.insight_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="insight_type"
                        value={editingAIInsight.insight_type || ""}
                        onChange={handleInputChange}
                        placeholder="Insight Type"
                      />
                    </div>
                  ) : (
                    aiInsight.insight_type
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAIInsight &&
                  editingAIInsight.insight_id === aiInsight.insight_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="insight_data"
                        value={editingAIInsight.insight_data || ""}
                        onChange={handleInputChange}
                        placeholder="Insight Data"
                      />
                    </div>
                  ) : (
                    aiInsight.insight_data
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAIInsight &&
                  editingAIInsight.insight_id === aiInsight.insight_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="insight_date"
                        value={editingAIInsight.insight_date || ""}
                        onChange={handleInputChange}
                        placeholder="Insight Date"
                      />
                    </div>
                  ) : (
                    aiInsight.insight_date
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAIInsight &&
                  editingAIInsight.insight_id === aiInsight.insight_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingAIInsight.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    aiInsight.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingAIInsight &&
                  editingAIInsight.insight_id === aiInsight.insight_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(aiInsight)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-1 rounded"
                        onClick={() => handleDelete(aiInsight.insight_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal for Creating a New AI Insight */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Create New AI Insight
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="user_id">User ID</label>
                <input
                  type="text"
                  id="user_id"
                  name="user_id"
                  value={newAIInsight.user_id}
                  onChange={handleInputChange}
                  placeholder="User ID"
                />
                {errors.user_id && (
                  <span className="text-red-500">{errors.user_id}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="insight_type">Insight Type</label>
                <input
                  type="text"
                  id="insight_type"
                  name="insight_type"
                  value={newAIInsight.insight_type}
                  onChange={handleInputChange}
                  placeholder="Insight Type"
                />
                {errors.insight_type && (
                  <span className="text-red-500">{errors.insight_type}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="insight_data">Insight Data</label>
                {/* <textarea
                  id="insight_data"
                  name="insight_data"
                  value={newAIInsight.insight_data}
                  onChange={handleInputChange}
                  placeholder="Insight Data"
                  className="w-full"
                /> */}
                <textarea
                  name="insight_data"
                  value={newAIInsight.insight_data || ""}
                  onChange={handleInputChange}
                  placeholder="Insight Data"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.insight_data && (
                  <span className="text-red-500">{errors.insight_data}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="insight_date">Insight Date</label>
                <input
                  type="date"
                  id="insight_date"
                  name="insight_date"
                  value={newAIInsight.insight_date}
                  onChange={handleInputChange}
                  placeholder="Insight Date"
                />
                {errors.insight_date && (
                  <span className="text-red-500">{errors.insight_date}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                <textarea
                  name="remark"
                  value={newAIInsight.remark || ""}
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.remark && (
                  <span className="text-red-500">{errors.remark}</span>
                )}
              </div>
              <div className="col-span-4 text-center">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded mr-4"
                  type="button"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                  type="button"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIInsightList;
