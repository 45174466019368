// prutl-frontend-npm6node14/src/components/sponsorshipManagement/SponsorshipList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSponsorships,
  createNewSponsorship,
  updateSponsorship,
  deleteSponsorship,
} from "../../redux/slices/sponsorshipSlice.js";

const SponsorshipList = () => {
  const dispatch = useDispatch();
  const { sponsorships, loading, error } = useSelector((state) => state.sponsorships);
  const [editingSponsorship, setEditingSponsorship] = useState(null);
  const [newSponsorship, setNewSponsorship] = useState({
    sponsor_id: "",
    team_id: "",
    event_id: "",
    amount: "",
    sponsorship_date: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllSponsorships());
  }, [dispatch]);

  const handleDelete = (sponsorshipId) => {
    dispatch(deleteSponsorship(sponsorshipId));
  };

  const handleEdit = (sponsorship) => {
    setEditingSponsorship({ ...sponsorship });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingSponsorship) {
      setEditingSponsorship({ ...editingSponsorship, [name]: value });
    } else {
      setNewSponsorship({ ...newSponsorship, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.sponsor_id) newErrors.sponsor_id = "Sponsor ID is required";
    if (!formData.team_id) newErrors.team_id = "Team ID is required";
    if (!formData.event_id) newErrors.event_id = "Event ID is required";
    if (!formData.amount) newErrors.amount = "Amount is required";
    if (!formData.sponsorship_date) newErrors.sponsorship_date = "Sponsorship Date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingSponsorship) {
      if (editingSponsorship.sponsorship_id && editingSponsorship) {
        dispatch(updateSponsorship({ sponsorshipId: editingSponsorship.sponsorship_id, data: editingSponsorship }));
      } else {
        console.error("Error: sponsorshipId or editingSponsorship is undefined");
      }
      setEditingSponsorship(null);
    } else {
      if (validateForm(newSponsorship)) {
        dispatch(createNewSponsorship(newSponsorship));
        setNewSponsorship({
          sponsor_id: "",
          team_id: "",
          event_id: "",
          amount: "",
          sponsorship_date: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingSponsorship(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading sponsorships...");
    }
    if (error) {
      console.error("Error fetching sponsorships:", error);
    }
    if (sponsorships.length > 0) {
      console.log("Fetched sponsorships:", sponsorships);
    }
  }, [loading, error, sponsorships]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="sponsorship-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Sponsorship
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete">
            <tr>
            <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
            Sponsorship ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Sponsor ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Team ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Event ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Amount</th>
              <th className="px-4 py-2 whitespace-nowrap">Sponsorship Date</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">Actions</th>
            </tr>
          </thead>
          <tbody>
            {sponsorships.map((sponsorship) => (
              <tr key={sponsorship.sponsorship_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{sponsorship.sponsorship_id}</td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsorship && editingSponsorship.sponsorship_id === sponsorship.sponsorship_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="sponsor_id"
                        value={editingSponsorship.sponsor_id || ""}
                        onChange={handleInputChange}
                        placeholder="Sponsor ID"
                      />
                    </div>
                  ) : (
                    sponsorship.sponsor_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsorship && editingSponsorship.sponsorship_id === sponsorship.sponsorship_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="team_id"
                        value={editingSponsorship.team_id || ""}
                        onChange={handleInputChange}
                        placeholder="Team ID"
                      />
                    </div>
                  ) : (
                    sponsorship.team_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsorship && editingSponsorship.sponsorship_id === sponsorship.sponsorship_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="event_id"
                        value={editingSponsorship.event_id || ""}
                        onChange={handleInputChange}
                        placeholder="Event ID"
                      />
                    </div>
                  ) : (
                    sponsorship.event_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsorship && editingSponsorship.sponsorship_id === sponsorship.sponsorship_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="amount"
                        value={editingSponsorship.amount || ""}
                        onChange={handleInputChange}
                        placeholder="Amount"
                      />
                    </div>
                  ) : (
                    sponsorship.amount
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsorship && editingSponsorship.sponsorship_id === sponsorship.sponsorship_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="sponsorship_date"
                        value={editingSponsorship.sponsorship_date || ""}
                        onChange={handleInputChange}
                        placeholder="Sponsorship Date"
                      />
                    </div>
                  ) : (
                    new Date(sponsorship.sponsorship_date).toLocaleDateString()
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsorship && editingSponsorship.sponsorship_id === sponsorship.sponsorship_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingSponsorship.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    sponsorship.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingSponsorship && editingSponsorship.sponsorship_id === sponsorship.sponsorship_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(sponsorship)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-1 rounded"
                        onClick={() => handleDelete(sponsorship.sponsorship_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Creating a New Sponsorship */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
        <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
          <h2 className="text-2xl font-semibold mb-4">Create New Sponsorship</h2>
          <form className="grid grid-cols-4 gap-4">


          <div className="input-group row-span-3">
              <label htmlFor="sponsor_id" >
                Sponsor ID
              </label>
              <input
                type="text"
                id="sponsor_id"
                name="sponsor_id"
                value={newSponsorship.sponsor_id}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border rounded"
                placeholder="Sponsor ID"
              />
              {errors.sponsor_id && (
                <span className="text-red-500 text-sm">{errors.sponsor_id}</span>
              )}
            </div>
            <div className="input-group row-span-3">
                              <label htmlFor="team_id" >
                Team ID
              </label>
              <input
                type="text"
                id="team_id"
                name="team_id"
                value={newSponsorship.team_id}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border rounded"
                placeholder="Team ID"
              />
              {errors.team_id && (
                <span className="text-red-500 text-sm">{errors.team_id}</span>
              )}
            </div>
            <div className="input-group row-span-3">
              <label htmlFor="event_id" >
                Event ID
              </label>
              <input
                type="text"
                id="event_id"
                name="event_id"
                value={newSponsorship.event_id}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border rounded"
                placeholder="Event ID"
              />
              {errors.event_id && (
                <span className="text-red-500 text-sm">{errors.event_id}</span>
              )}
            </div>
            <div className="input-group row-span-3">
              <label htmlFor="amount" >
                Amount
              </label>
              <input
                type="text"
                id="amount"
                name="amount"
                value={newSponsorship.amount}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border rounded"
                placeholder="Amount"
              />
              {errors.amount && (
                <span className="text-red-500 text-sm">{errors.amount}</span>
              )}
            </div>
            <div className="input-group row-span-3">
              <label htmlFor="sponsorship_date" >
                Sponsorship Date
              </label>
              <input
                type="date"
                id="sponsorship_date"
                name="sponsorship_date"
                value={newSponsorship.sponsorship_date}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border rounded"
                placeholder="Sponsorship Date"
              />
              {errors.sponsorship_date && (
                <span className="text-red-500 text-sm">{errors.sponsorship_date}</span>
              )}
            </div>
            <div className="input-group row-span-3">
              <label htmlFor="remark" >
                Remark
              </label>
              
              <textarea
            name="remark"
            value={ newSponsorship.remark || ""}
            onChange={handleInputChange}
            placeholder="Remark"
            className="w-full p-2 mb-4 border rounded"
          />
            </div>
            <div className="col-span-4 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
          </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SponsorshipList;
