// prutl-frontend-npm6node14/src/components/committeeManagement/CommitteeList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCommittees,
  createNewCommittee,
  updateCommittee,
  deleteCommittee,
} from "../../redux/slices/committeeSlice.js";

const CommitteeList = () => {
  const dispatch = useDispatch();
  const { committees, loading, error } = useSelector((state) => state.committees);
  const [editingCommittee, setEditingCommittee] = useState(null);
  const [newCommittee, setNewCommittee] = useState({
    event_id: "",
    committee_name: "",
    committee_type: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllCommittees());
  }, [dispatch]);

  const handleDelete = (committeeId) => {
    dispatch(deleteCommittee(committeeId));
  };

  const handleEdit = (committee) => {
    setEditingCommittee({ ...committee });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingCommittee) {
      setEditingCommittee({ ...editingCommittee, [name]: value });
    } else {
      setNewCommittee({ ...newCommittee, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.event_id) newErrors.event_id = "Event ID is required";
    if (!formData.committee_name) newErrors.committee_name = "Committee name is required";
    if (!formData.committee_type) newErrors.committee_type = "Committee type is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingCommittee) {
      if (editingCommittee.committee_id && editingCommittee) {
        dispatch(
          updateCommittee({ committeeId: editingCommittee.committee_id, data: editingCommittee })
        );
      } else {
        console.error("Error: committeeId or editingCommittee is undefined");
      }
      setEditingCommittee(null);
    } else {
      if (validateForm(newCommittee)) {
        dispatch(createNewCommittee(newCommittee));
        setNewCommittee({
          event_id: "",
          committee_name: "",
          committee_type: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingCommittee(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading committees...");
    }
    if (error) {
      console.error("Error fetching committees:", error);
    }
    if (committees.length > 0) {
      console.log("Fetched committees:", committees);
    }
  }, [loading, error, committees]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="committee-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Committee
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">

      <table className="min-w-full divide-y divide-gray-200">
      <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
      <tr>
      <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
      Committee ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Event ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Committee Name</th>
            <th className="px-4 py-2 whitespace-nowrap">Committee Type</th>
            <th className="px-4 py-2 whitespace-nowrap">Remark</th>
            <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">Actions</th>
            </tr>
        </thead>
        <tbody>
          {committees.map((committee) => (
            <tr key={committee.committee_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{committee.committee_id}</td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingCommittee && editingCommittee.committee_id === committee.committee_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="event_id"
                      value={editingCommittee.event_id || ""}
                      onChange={handleInputChange}
                      placeholder="Event ID"
                    />
                  </div>
                ) : (
                  committee.event_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingCommittee && editingCommittee.committee_id === committee.committee_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="committee_name"
                      value={editingCommittee.committee_name || ""}
                      onChange={handleInputChange}
                      placeholder="Committee Name"
                    />
                  </div>
                ) : (
                  committee.committee_name
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingCommittee && editingCommittee.committee_id === committee.committee_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="committee_type"
                      value={editingCommittee.committee_type || ""}
                      onChange={handleInputChange}
                      placeholder="Committee Type"
                    />
                  </div>
                ) : (
                  committee.committee_type
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingCommittee && editingCommittee.committee_id === committee.committee_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="remark"
                      value={editingCommittee.remark || ""}
                      onChange={handleInputChange}
                      placeholder="Remark"
                    />
                  </div>
                ) : (
                  committee.remark
                )}
              </td>
  <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                {editingCommittee && editingCommittee.committee_id === committee.committee_id ? (
                  <>
                    <button
                      className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      className="bg-gray-500 text-white px-3 py-1 rounded"
                      onClick={handleUndo}
                    >
                      Undo
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                      onClick={() => handleEdit(committee)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                      onClick={() => handleDelete(committee.committee_id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Register New Committee
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="event_id">
                  Event ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="event_id"
                  value={newCommittee.event_id || ""}
                  onChange={handleInputChange}
                  placeholder="Event ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.event_id && (
                  <p className="text-red-500">{errors.event_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="committee_name">
                  Committee Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="committee_name"
                  value={newCommittee.committee_name || ""}
                  onChange={handleInputChange}
                  placeholder="Committee Name"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.committee_name && (
                  <p className="text-red-500">{errors.committee_name}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="committee_type">
                  Committee Type <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="committee_type"
                  value={newCommittee.committee_type || ""}
                  onChange={handleInputChange}
                  placeholder="Committee Type"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.committee_type && (
                  <p className="text-red-500">{errors.committee_type}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                
                <textarea
            name="remark"
            value={newCommittee.remark || ""}
            onChange={handleInputChange}
            placeholder="Remark"
            className="w-full p-2 mb-4 border rounded"
          />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommitteeList;
