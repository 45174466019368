// prutl-frontend-npm6node14/src/components/teamManagement/TeamList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTeams,
  createNewTeam,
  updateTeam,
  deleteTeam,
} from "../../redux/slices/teamSlice.js";

const TeamList = () => {
  const dispatch = useDispatch();
  const { teams, loading, error } = useSelector((state) => state.teams);
  const [editingTeam, setEditingTeam] = useState(null);
  const [newTeam, setNewTeam] = useState({
    team_code: "",
    team_name: "",
    sponsor_id: "",
    competition_id: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllTeams());
  }, [dispatch]);

  const handleDelete = (teamId) => {
    dispatch(deleteTeam(teamId));
  };

  const handleEdit = (team) => {
    setEditingTeam({ ...team });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingTeam) {
      setEditingTeam({ ...editingTeam, [name]: value });
    } else {
      setNewTeam({ ...newTeam, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.team_code) newErrors.team_code = "Team code is required";
    if (!formData.team_name) newErrors.team_name = "Team name is required";
    if (!formData.sponsor_id) newErrors.sponsor_id = "Sponsor ID is required";
    if (!formData.competition_id) newErrors.competition_id = "Competition ID is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingTeam) {
      if (editingTeam.team_id && editingTeam) {
        dispatch(
          updateTeam({ teamId: editingTeam.team_id, data: editingTeam })
        );
      } else {
        console.error("Error: teamId or editingTeam is undefined");
      }
      setEditingTeam(null);
    } else {
      if (validateForm(newTeam)) {
        dispatch(createNewTeam(newTeam));
        setNewTeam({
          team_code: "",
          team_name: "",
          sponsor_id: "",
          competition_id: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingTeam(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading teams...");
    }
    if (error) {
      console.error("Error fetching teams:", error);
    }
    if (teams.length > 0) {
      console.log("Fetched teams:", teams);
    }
  }, [loading, error, teams]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="team-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Team
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        
      <table className="min-w-full divide-y divide-gray-200">
      <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
      <tr>
      <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
      Team ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Team Code</th>
            <th className="px-4 py-2 whitespace-nowrap">Team Name</th>
            <th className="px-4 py-2 whitespace-nowrap">Sponsor ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Competition ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Remark</th>
            <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">Actions</th>
            </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.team_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{team.team_id}</td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingTeam && editingTeam.team_id === team.team_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="team_code"
                      value={editingTeam.team_code || ""}
                      onChange={handleInputChange}
                      placeholder="Team Code"
                    />
                  </div>
                ) : (
                  team.team_code
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingTeam && editingTeam.team_id === team.team_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="team_name"
                      value={editingTeam.team_name || ""}
                      onChange={handleInputChange}
                      placeholder="Team Name"
                    />
                  </div>
                ) : (
                  team.team_name
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingTeam && editingTeam.team_id === team.team_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="sponsor_id"
                      value={editingTeam.sponsor_id || ""}
                      onChange={handleInputChange}
                      placeholder="Sponsor ID"
                    />
                  </div>
                ) : (
                  team.sponsor_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingTeam && editingTeam.team_id === team.team_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="competition_id"
                      value={editingTeam.competition_id || ""}
                      onChange={handleInputChange}
                      placeholder="Competition ID"
                    />
                  </div>
                ) : (
                  team.competition_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingTeam && editingTeam.team_id === team.team_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="remark"
                      value={editingTeam.remark || ""}
                      onChange={handleInputChange}
                      placeholder="Remark"
                    />
                  </div>
                ) : (
                  team.remark
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
              {editingTeam && editingTeam.team_id === team.team_id ? (
                  <>
                    <button
                      className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      className="bg-gray-500 text-white px-3 py-1 rounded"
                      onClick={handleUndo}
                    >
                      Undo
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                      onClick={() => handleEdit(team)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                      onClick={() => handleDelete(team.team_id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
  

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Register New Team
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="team_code">
                  Team Code <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="team_code"
                  value={newTeam.team_code || ""}
                  onChange={handleInputChange}
                  placeholder="Team Code"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.team_code && (
                  <p className="text-red-500">{errors.team_code}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="team_name">
                  Team Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="team_name"
                  value={newTeam.team_name || ""}
                  onChange={handleInputChange}
                  placeholder="Team Name"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.team_name && (
                  <p className="text-red-500">{errors.team_name}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="sponsor_id">
                  Sponsor ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="sponsor_id"
                  value={newTeam.sponsor_id || ""}
                  onChange={handleInputChange}
                  placeholder="Sponsor ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.sponsor_id && (
                  <p className="text-red-500">{errors.sponsor_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="competition_id">
                  Competition ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="competition_id"
                  value={newTeam.competition_id || ""}
                  onChange={handleInputChange}
                  placeholder="Competition ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.competition_id && (
                  <p className="text-red-500">{errors.competition_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                
                 <textarea
            name="remark"
            value={newTeam.remark || ""}
            onChange={handleInputChange}
            placeholder="Remark"
            className="w-full p-2 mb-4 border rounded"
          />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamList;
