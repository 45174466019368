// prutl-frontend-npm6node14/src/components/awardManagement/AwardList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAwards,
  createNewAward,
  updateAward,
  deleteAward,
} from "../../redux/slices/awardSlice.js";

const AwardList = () => {
  const dispatch = useDispatch();
  const { awards, loading, error } = useSelector((state) => state.awards);
  const [editingAward, setEditingAward] = useState(null);
  const [newAward, setNewAward] = useState({
    award_name: "",
    award_date: "",
    recipient_id: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllAwards());
  }, [dispatch]);

  const handleDelete = (awardId) => {
    dispatch(deleteAward(awardId));
  };

  const handleEdit = (award) => {
    setEditingAward({ ...award });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingAward) {
      setEditingAward({ ...editingAward, [name]: value });
    } else {
      setNewAward({ ...newAward, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.award_name) newErrors.award_name = "Award Name is required";
    if (!formData.award_date) newErrors.award_date = "Award Date is required";
    if (!formData.recipient_id)
      newErrors.recipient_id = "Recipient ID is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingAward) {
      if (editingAward.award_id && editingAward) {
        dispatch(
          updateAward({ awardId: editingAward.award_id, data: editingAward })
        );
      } else {
        console.error("Error: awardId or editingAward is undefined");
      }
      setEditingAward(null);
    } else {
      if (validateForm(newAward)) {
        dispatch(createNewAward(newAward));
        setNewAward({
          award_name: "",
          award_date: "",
          recipient_id: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingAward(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading awards...");
    }
    if (error) {
      console.error("Error fetching awards:", error);
    }
    if (awards.length > 0) {
      console.log("Fetched awards:", awards);
    }
  }, [loading, error, awards]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="award-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Award
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Award ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">Award Name</th>
              <th className="px-4 py-2 whitespace-nowrap">Award Date</th>
              <th className="px-4 py-2 whitespace-nowrap">Recipient ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {awards.map((award) => (
              <tr key={award.award_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {award.award_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAward && editingAward.award_id === award.award_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="award_name"
                        value={editingAward.award_name || ""}
                        onChange={handleInputChange}
                        placeholder="Award Name"
                      />
                    </div>
                  ) : (
                    award.award_name
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAward && editingAward.award_id === award.award_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="award_date"
                        value={editingAward.award_date || ""}
                        onChange={handleInputChange}
                        placeholder="Award Date"
                      />
                    </div>
                  ) : (
                    new Date(award.award_date).toLocaleDateString()
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAward && editingAward.award_id === award.award_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="recipient_id"
                        value={editingAward.recipient_id || ""}
                        onChange={handleInputChange}
                        placeholder="Recipient ID"
                      />
                    </div>
                  ) : (
                    award.recipient_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingAward && editingAward.award_id === award.award_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingAward.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    award.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0  tableHeadEditDelete">
                  {editingAward && editingAward.award_id === award.award_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(award)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                        onClick={() => handleDelete(award.award_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">Register New Award</h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="award_name">
                  Award Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="award_name"
                  value={newAward.award_name || ""}
                  onChange={handleInputChange}
                  placeholder="Award Name"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.award_name && (
                  <p className="text-red-500">{errors.award_name}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="award_date">
                  Award Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="award_date"
                  value={newAward.award_date || ""}
                  onChange={handleInputChange}
                  placeholder="Award Date"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.award_date && (
                  <p className="text-red-500">{errors.award_date}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="recipient_id">
                  Recipient ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="recipient_id"
                  value={newAward.recipient_id || ""}
                  onChange={handleInputChange}
                  placeholder="Recipient ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.recipient_id && (
                  <p className="text-red-500">{errors.recipient_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>

                <textarea
                  name="remark"
                  value={newAward.remark || ""}
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AwardList;
