// prutl-frontend-npm6node14/src/components/teamManagement/FamilyList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFamilies,
  createNewFamily,
  updateFamily,
  deleteFamily,
} from "../../redux/slices/familySlice.js";

const FamilyList = () => {
  const dispatch = useDispatch();
  const { families, loading, error } = useSelector((state) => state.families);
  const [editingFamily, setEditingFamily] = useState(null);
  const [newFamily, setNewFamily] = useState({
    family_code: "",
    family_name: "",
    address: "",
    phone_number: "",
    contact_person: "",
    city: "",
    county: "",
    state: "",
    country: "",
    pin_code: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllFamilies());
  }, [dispatch]);

  const handleDelete = (familyId) => {
    dispatch(deleteFamily(familyId));
  };

  const handleEdit = (family) => {
    setEditingFamily({ ...family });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingFamily) {
      setEditingFamily({ ...editingFamily, [name]: value });
    } else {
      setNewFamily({ ...newFamily, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.family_code)
      newErrors.family_code = "Family Code is required";
    if (!formData.family_name)
      newErrors.family_name = "Family Name is required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.phone_number)
      newErrors.phone_number = "Phone Number is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingFamily) {
      if (editingFamily.family_id && editingFamily) {
        dispatch(
          updateFamily({
            familyId: editingFamily.family_id,
            data: editingFamily,
          })
        );
      } else {
        console.error("Error: familyId or editingFamily is undefined");
      }
      setEditingFamily(null);
    } else {
      if (validateForm(newFamily)) {
        dispatch(createNewFamily(newFamily));
        setNewFamily({
          family_code: "",
          family_name: "",
          address: "",
          phone_number: "",
          contact_person: "",
          city: "",
          county: "",
          state: "",
          country: "",
          pin_code: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingFamily(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading families...");
    }
    if (error) {
      console.error("Error fetching families:", error);
    }
    if (families.length > 0) {
      console.log("Fetched families:", families);
    }
  }, [loading, error, families]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="family-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Family
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Family ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">Family Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Family Name</th>
              <th className="px-4 py-2 whitespace-nowrap">Address</th>
              <th className="px-4 py-2 whitespace-nowrap">Phone Number</th>
              <th className="px-4 py-2 whitespace-nowrap">Contact Person</th>
              <th className="px-4 py-2 whitespace-nowrap">City</th>
              <th className="px-4 py-2 whitespace-nowrap">County</th>
              <th className="px-4 py-2 whitespace-nowrap">State</th>
              <th className="px-4 py-2 whitespace-nowrap">Country</th>
              <th className="px-4 py-2 whitespace-nowrap">Pin Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {families.map((family) => (
              <tr key={family.family_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {family.family_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="family_code"
                        value={editingFamily.family_code || ""}
                        onChange={handleInputChange}
                        placeholder="Family Code"
                      />
                    </div>
                  ) : (
                    family.family_code
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="family_name"
                        value={editingFamily.family_name || ""}
                        onChange={handleInputChange}
                        placeholder="Family Name"
                      />
                    </div>
                  ) : (
                    family.family_name
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="address"
                        value={editingFamily.address || ""}
                        onChange={handleInputChange}
                        placeholder="Address"
                      />
                    </div>
                  ) : (
                    family.address
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="phone_number"
                        value={editingFamily.phone_number || ""}
                        onChange={handleInputChange}
                        placeholder="Phone Number"
                      />
                    </div>
                  ) : (
                    family.phone_number
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="contact_person"
                        value={editingFamily.contact_person || ""}
                        onChange={handleInputChange}
                        placeholder="Contact Person"
                      />
                    </div>
                  ) : (
                    family.contact_person
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="city"
                        value={editingFamily.city || ""}
                        onChange={handleInputChange}
                        placeholder="City"
                      />
                    </div>
                  ) : (
                    family.city
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="county"
                        value={editingFamily.county || ""}
                        onChange={handleInputChange}
                        placeholder="County"
                      />
                    </div>
                  ) : (
                    family.county
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="state"
                        value={editingFamily.state || ""}
                        onChange={handleInputChange}
                        placeholder="State"
                      />
                    </div>
                  ) : (
                    family.state
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="country"
                        value={editingFamily.country || ""}
                        onChange={handleInputChange}
                        placeholder="Country"
                      />
                    </div>
                  ) : (
                    family.country
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="pin_code"
                        value={editingFamily.pin_code || ""}
                        onChange={handleInputChange}
                        placeholder="Pin Code"
                      />
                    </div>
                  ) : (
                    family.pin_code
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingFamily.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    family.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingFamily &&
                  editingFamily.family_id === family.family_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(family)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-1 rounded"
                        onClick={() => handleDelete(family.family_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal for Creating a New Family */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">Create New Family</h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="family_code">Family Code</label>
                <input
                  type="text"
                  id="family_code"
                  name="family_code"
                  value={newFamily.family_code}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Family Code"
                />
                {errors.family_code && (
                  <p className="error-message">{errors.family_code}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="family_name">Family Name</label>
                <input
                  type="text"
                  id="family_name"
                  name="family_name"
                  value={newFamily.family_name}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Family Name"
                />
                {errors.family_name && (
                  <p className="error-message">{errors.family_name}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={newFamily.address}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Address"
                />
                {errors.address && (
                  <p className="error-message">{errors.address}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="phone_number">Phone Number</label>
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  value={newFamily.phone_number}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Phone Number"
                />
                {errors.phone_number && (
                  <p className="error-message">{errors.phone_number}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="contact_person">Contact Person</label>
                <input
                  type="text"
                  id="contact_person"
                  name="contact_person"
                  value={newFamily.contact_person}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Contact Person"
                />
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={newFamily.city}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="City"
                />
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="county">County</label>
                <input
                  type="text"
                  id="county"
                  name="county"
                  value={newFamily.county}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="County"
                />
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={newFamily.state}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="State"
                />
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={newFamily.country}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Country"
                />
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="pin_code">Pin Code</label>
                <input
                  type="text"
                  id="pin_code"
                  name="pin_code"
                  value={newFamily.pin_code}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Pin Code"
                />
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                <textarea
                  name="remark"
                  value={newFamily.remark || ""}
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FamilyList;
