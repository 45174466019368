// prutl-frontend-npm6node14/src/components/sponsorManagement/SponsorList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSponsors,
  createNewSponsor,
  updateSponsor,
  deleteSponsor,
} from "../../redux/slices/sponsorSlice.js";

const SponsorList = () => {
  const dispatch = useDispatch();
  const { sponsors, loading, error } = useSelector((state) => state.sponsors);
  const [editingSponsor, setEditingSponsor] = useState(null);
  const [newSponsor, setNewSponsor] = useState({
    sponsor_name: "",
    contact_info: "",
    phone_number: "",
    sponsorship_amount: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllSponsors());
  }, [dispatch]);

  const handleDelete = (sponsorId) => {
    dispatch(deleteSponsor(sponsorId));
  };

  const handleEdit = (sponsor) => {
    setEditingSponsor({ ...sponsor });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingSponsor) {
      setEditingSponsor({ ...editingSponsor, [name]: value });
    } else {
      setNewSponsor({ ...newSponsor, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.sponsor_name)
      newErrors.sponsor_name = "Sponsor name is required";
    if (!formData.contact_info)
      newErrors.contact_info = "Contact info is required";
    if (!formData.phone_number)
      newErrors.phone_number = "Phone number is required";
    if (!formData.sponsorship_amount)
      newErrors.sponsorship_amount = "Sponsorship amount is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingSponsor) {
      if (editingSponsor.sponsor_id && editingSponsor) {
        dispatch(
          updateSponsor({
            sponsorId: editingSponsor.sponsor_id,
            data: editingSponsor,
          })
        );
      } else {
        console.error("Error: sponsorId or editingSponsor is undefined");
      }
      setEditingSponsor(null);
    } else {
      if (validateForm(newSponsor)) {
        dispatch(createNewSponsor(newSponsor));
        setNewSponsor({
          sponsor_name: "",
          contact_info: "",
          phone_number: "",
          sponsorship_amount: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingSponsor(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading sponsors...");
    }
    if (error) {
      console.error("Error fetching sponsors:", error);
    }
    if (sponsors.length > 0) {
      console.log("Fetched sponsors:", sponsors);
    }
  }, [loading, error, sponsors]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="sponsor-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Sponsor
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Sponsor ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">Sponsor Name</th>
              <th className="px-4 py-2 whitespace-nowrap">Contact Info</th>
              <th className="px-4 py-2 whitespace-nowrap">Phone Number</th>
              <th className="px-4 py-2 whitespace-nowrap">
                Sponsorship Amount
              </th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {sponsors.map((sponsor) => (
              <tr key={sponsor.sponsor_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {sponsor.sponsor_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsor &&
                  editingSponsor.sponsor_id === sponsor.sponsor_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="sponsor_name"
                        value={editingSponsor.sponsor_name || ""}
                        onChange={handleInputChange}
                        placeholder="Sponsor Name"
                      />
                    </div>
                  ) : (
                    sponsor.sponsor_name
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsor &&
                  editingSponsor.sponsor_id === sponsor.sponsor_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="contact_info"
                        value={editingSponsor.contact_info || ""}
                        onChange={handleInputChange}
                        placeholder="Contact Info"
                      />
                    </div>
                  ) : (
                    sponsor.contact_info
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsor &&
                  editingSponsor.sponsor_id === sponsor.sponsor_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="phone_number"
                        value={editingSponsor.phone_number || ""}
                        onChange={handleInputChange}
                        placeholder="Phone Number"
                      />
                    </div>
                  ) : (
                    sponsor.phone_number
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsor &&
                  editingSponsor.sponsor_id === sponsor.sponsor_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="sponsorship_amount"
                        value={editingSponsor.sponsorship_amount || ""}
                        onChange={handleInputChange}
                        placeholder="Sponsorship Amount"
                      />
                    </div>
                  ) : (
                    sponsor.sponsorship_amount
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingSponsor &&
                  editingSponsor.sponsor_id === sponsor.sponsor_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingSponsor.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    sponsor.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingSponsor &&
                  editingSponsor.sponsor_id === sponsor.sponsor_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(sponsor)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                        onClick={() => handleDelete(sponsor.sponsor_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Register New Sponsor
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="sponsor_name">
                  Sponsor Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="sponsor_name"
                  value={newSponsor.sponsor_name || ""}
                  onChange={handleInputChange}
                  placeholder="Sponsor Name"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.sponsor_name && (
                  <p className="text-red-500">{errors.sponsor_name}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="contact_info">
                  Contact Info <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="contact_info"
                  value={newSponsor.contact_info || ""}
                  onChange={handleInputChange}
                  placeholder="Contact Info"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.contact_info && (
                  <p className="text-red-500">{errors.contact_info}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="phone_number">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="phone_number"
                  value={newSponsor.phone_number || ""}
                  onChange={handleInputChange}
                  placeholder="Phone Number"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.phone_number && (
                  <p className="text-red-500">{errors.phone_number}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="sponsorship_amount">
                  Sponsorship Amount <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="sponsorship_amount"
                  value={newSponsor.sponsorship_amount || ""}
                  onChange={handleInputChange}
                  placeholder="Sponsorship Amount"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.sponsorship_amount && (
                  <p className="text-red-500">{errors.sponsorship_amount}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>

                <textarea
                  name="remark"
                  value={newSponsor.remark || ""}
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SponsorList;
