//PRUTL-frontend-npm6node14/src/pages/Home.jsx
import React from "react";
import { Link } from "react-router-dom";
import wallpaperImage from "../assets/kidsPulling.jpg";
import wallpaperImageScrap from "../assets/scrap.jpg";
import wallpaperImageOfficeCollegue from "../assets/officeCollegue.jpg";
import wallpaperImageBalanceStones from "../assets/BalanceStones.jpg";
import profileImage from "../assets/profileImage.png";
import remarkProfileImage from "../assets/samGarcia.png";

const Home = () => {
  return (
    <div className="main-content">
      <div>
        <p className="heading">Collaborative Project Management Platform</p>
        <p className="head-text-info">
          Join PRUTL to access a diverse network of experts who can assist
          in managing projects and transforming concepts into profitable
          products. Benefit from collaborative tools and resources for revenue
          growth.
        </p>
        <Link to="/contactUs" className="contactusbtn">
          Contact us
        </Link>
      </div>
      <div className="additional-info">
        <div className="rating">
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>
        <div className="info-section">
          <p className="text-xs"> Diverse Collaboration</p>
        </div>
        <div className="info-section">
          <p className="text-xs">Revenue Growth</p>
        </div>
      </div>
      <p className="text-sm"> “Enhanced my project success significantly.” </p>

      <div className="profile-container">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <span className="profile-username">Yan Harris</span>
      </div>

      <div className="wallpaperImage-container">
        <div className="rectangleSvg">
          <svg
            className="pattern-svg absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0"
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="pattern-rect"
                  fill="#e1e3e6"
                ></rect>
              </pattern>
            </defs>
            <rect
              width="404"
              height="392"
              fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
            ></rect>
          </svg>
        </div>
        <img
          src={wallpaperImage}
          alt="Profile"
          className="wallpaperImage-image"
        />
      </div>
      <div className="convert-ideas-text-container">
        <h2 className="convert-ideas-text">Collaborate with Diverse Experts</h2>
        <div className="subheading-text">
          Access a network of academicians, researchers, mentors, and more to
          bring your concepts to life and enhance revenue opportunities.
        </div>
      </div>
      <div className="convert-ideas-text-container">
        <h2 className="convert-ideas-text">Convert Ideas to Products</h2>
        <div className="subheading-text">
          Collaborate with experts for revenue opportunities.
        </div>
      </div>
      <div className="wallpaperImageScrap-container">
        <img
          src={wallpaperImageScrap}
          alt="Profile"
          className="wallpaperImageScrap-image"
        />
      </div>
      <div className="convert-ideas-text-container">
        <h2 className="convert-ideas-text">Efficient Project Management</h2>
        <div className="subheading-text">
          Utilize effective project management tools to streamline the process
          of converting ideas into marketable products, ensuring revenue growth.
        </div>
      </div>
      <div className="wallpaperImage-container">
        <img
          src={wallpaperImageOfficeCollegue}
          alt="Profile"
          className="wallpaperImage-image"
        />
      </div>
      <div className="convert-ideas-text-container">
        <h2 className="convert-ideas-text">Revenue Optimization</h2>
        <div className="subheading-text">
          Maximize revenue opportunities by leveraging the expertise and
          resources available on PRUTL to refine and launch successful
          products.
        </div>
      </div>
      <div className="wallpaperImageScrap-container">
        <img
          src={wallpaperImageBalanceStones}
          alt="Profile"
          className="wallpaperImageScrap-image"
        />
      </div>
      <div className="bottom-remark-card">
        <div className="remark-text">
          "Maximize revenue opportunities by leveraging the expertise and
          resources available on PRUTL to refine and launch successful
          products."
        </div>
        <div className="remark-profile-image-container">
  <div className="remark-profile-image-wrapper">
    <img
      src={remarkProfileImage}
      alt="Remark Profile"
      className="remark-profile-image"
    />
  </div>
  <div className="remark-profile-username-wrapper">
    <span className="remark-profile-username">Sam Garcia</span>
  </div>
</div>

      </div>
      <div className="connectForSuccess-card">
        <div className="connectForSuccess-container">
          <div>
            <h2 className="connectForSuccess-text">
              Connect for Success <span className="text-primary">.</span>
            </h2>
            <div className="connectForSuccessSubheading-text">
              "
              <span>
                PRUTL has been instrumental in transforming my ideas into
                profitable products. The collaborative environment and expert
                guidance have significantly boosted my project success.
              </span>
              "<span> - Nathan Williams</span>
            </div>
          </div>
          <div className="bottomContactbutton">
            <Link to="/contactUs" className="contactusbtn">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      {/* <Link to="/signup" className="btn">Get Started</Link> */}
    </div>
  );
};

const Star = () => (
  <svg viewBox="0 0 20 20" width="1.2em" height="1.2em" className="star">
    <path
      fill="currentColor"
      d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292Z"
    ></path>
  </svg>
);

export default Home;
