// prutl-frontend-npm6node14/src/components/dimensionScoreManagement/DimensionScoreList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDimensionScores,
  createNewDimensionScore,
  updateDimensionScore,
  deleteDimensionScore,
} from "../../redux/slices/dimensionScoreSlice.js";

const DimensionScoreList = () => {
  const dispatch = useDispatch();
  const { dimensionScores, loading, error } = useSelector(
    (state) => state.dimensionScores
  );
  const [editingScore, setEditingScore] = useState(null);
  const [newScore, setNewScore] = useState({
    user_id: "",
    dimension_id: "",
    score_value: "",
    assessment_date: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllDimensionScores());
  }, [dispatch]);

  const handleDelete = (dimensionScoreId) => {
    dispatch(deleteDimensionScore(dimensionScoreId));
  };

  const handleEdit = (score) => {
    setEditingScore({ ...score });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingScore) {
      setEditingScore({ ...editingScore, [name]: value });
    } else {
      setNewScore({ ...newScore, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.user_id) newErrors.user_id = "User ID is required";
    if (!formData.dimension_id)
      newErrors.dimension_id = "Dimension ID is required";
    if (!formData.score_value)
      newErrors.score_value = "Score Value is required";
    if (!formData.assessment_date)
      newErrors.assessment_date = "Assessment Date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingScore) {
      if (editingScore.dimension_score_id && editingScore) {
        dispatch(
          updateDimensionScore({
            dimensionScoreId: editingScore.dimension_score_id,
            data: editingScore,
          })
        );
      } else {
        console.error("Error: dimensionScoreId or editingScore is undefined");
      }
      setEditingScore(null);
    } else {
      if (validateForm(newScore)) {
        dispatch(createNewDimensionScore(newScore));
        setNewScore({
          user_id: "",
          dimension_id: "",
          score_value: "",
          assessment_date: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingScore(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading dimension scores...");
    }
    if (error) {
      console.error("Error fetching dimension scores:", error);
    }
    if (dimensionScores.length > 0) {
      console.log("Fetched dimension scores:", dimensionScores);
    }
  }, [loading, error, dimensionScores]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="dimensionScore-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Score
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete">
            <tr>
            <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
            Dimension Score ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">
                User ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Dimension ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Score Value</th>
              <th className="px-4 py-2 whitespace-nowrap">Assessment Date</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {dimensionScores.map((score) => (
              <tr key={score.dimension_score_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{score.dimension_score_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore &&
                  editingScore.dimension_score_id ===
                    score.dimension_score_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="user_id"
                        value={editingScore.user_id || ""}
                        onChange={handleInputChange}
                        placeholder="User ID"
                      />
                    </div>
                  ) : (
                    score.user_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore &&
                  editingScore.dimension_score_id ===
                    score.dimension_score_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="dimension_id"
                        value={editingScore.dimension_id || ""}
                        onChange={handleInputChange}
                        placeholder="Dimension ID"
                      />
                    </div>
                  ) : (
                    score.dimension_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore &&
                  editingScore.dimension_score_id ===
                    score.dimension_score_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="score_value"
                        value={editingScore.score_value || ""}
                        onChange={handleInputChange}
                        placeholder="Score Value"
                      />
                    </div>
                  ) : (
                    score.score_value
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore &&
                  editingScore.dimension_score_id ===
                    score.dimension_score_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="assessment_date"
                        value={editingScore.assessment_date.split("T")[0] || ""}
                        onChange={handleInputChange}
                        placeholder="Assessment Date"
                      />
                    </div>
                  ) : (
                    score.assessment_date
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingScore &&
                  editingScore.dimension_score_id ===
                    score.dimension_score_id ? (
                    <div className="input-group">
                      <textarea
                        name="remark"
                        value={editingScore.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                        className="w-full p-2 mb-4 border rounded"
                      />
                    </div>
                  ) : (
                    score.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingScore &&
                  editingScore.dimension_score_id ===
                    score.dimension_score_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(score)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-1 rounded"
                        onClick={() => handleDelete(score.dimension_score_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Creating a New Dimension Score */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Create New Dimension Score
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="user_id">User ID</label>
                <input
                  type="text"
                  id="user_id"
                  name="user_id"
                  value={newScore.user_id}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="User ID"
                />
                {errors.user_id && (
                  <p className="text-red-500">{errors.user_id}</p>
                )}
              </div>

              <div className="input-group row-span-3">
                <label htmlFor="dimension_id">Dimension ID</label>
                <input
                  type="text"
                  id="dimension_id"
                  name="dimension_id"
                  value={newScore.dimension_id}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Dimension ID"
                />
                {errors.dimension_id && (
                  <p className="text-red-500">{errors.dimension_id}</p>
                )}
              </div>

              <div className="input-group row-span-3">
                <label htmlFor="score_value">Score Value</label>
                <input
                  type="text"
                  id="score_value"
                  name="score_value"
                  value={newScore.score_value}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Score Value"
                />
                {errors.score_value && (
                  <p className="text-red-500">{errors.score_value}</p>
                )}
              </div>

              <div className="input-group row-span-3">
                <label htmlFor="assessment_date">Assessment Date</label>

                <input
                  type="date"
                  id="assessment_date"
                  name="assessment_date"
                  value={newScore.assessment_date.split("T")[0] || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Assessment Date"
                />
                {errors.assessment_date && (
                  <p className="text-red-500">{errors.assessment_date}</p>
                )}
              </div>

              <div className="input-group col-span-4">
                <label htmlFor="remark">Remark</label>
                <textarea
                  id="remark"
                  name="remark"
                  value={newScore.remark}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                  placeholder="Remark"
                />
              </div>
            </form>
            <div className="flex justify-end mt-4">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 ml-4 rounded"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DimensionScoreList;
