// prutl-frontend-npm6node14/src/components/committeeMemberManagement/CommitteeMemberList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCommitteeMembers,
  createNewCommitteeMember,
  updateCommitteeMember,
  deleteCommitteeMember,
} from "../../redux/slices/committeeMemberSlice.js";

const CommitteeMemberList = () => {
  const dispatch = useDispatch();
  const { committeeMembers, loading, error } = useSelector((state) => state.committeeMembers);
  const [editingMember, setEditingMember] = useState(null);
  const [newMember, setNewMember] = useState({
    user_id: "",
    committee_id: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllCommitteeMembers());
  }, [dispatch]);

  const handleDelete = (committeeMemberId) => {
    dispatch(deleteCommitteeMember(committeeMemberId));
  };

  const handleEdit = (member) => {
    setEditingMember({ ...member });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingMember) {
      setEditingMember({ ...editingMember, [name]: value });
    } else {
      setNewMember({ ...newMember, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.user_id) newErrors.user_id = "User ID is required";
    if (!formData.committee_id) newErrors.committee_id = "Committee ID is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingMember) {
      if (editingMember.committee_member_id && editingMember) {
        dispatch(
          updateCommitteeMember({ committeeMemberId: editingMember.committee_member_id, data: editingMember })
        );
      } else {
        console.error("Error: committeeMemberId or editingMember is undefined");
      }
      setEditingMember(null);
    } else {
      if (validateForm(newMember)) {
        dispatch(createNewCommitteeMember(newMember));
        setNewMember({
          user_id: "",
          committee_id: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingMember(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading committee members...");
    }
    if (error) {
      console.error("Error fetching committee members:", error);
    }
    if (committeeMembers.length > 0) {
      console.log("Fetched committee members:", committeeMembers);
    }
  }, [loading, error, committeeMembers]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="committee-member-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Committee Member
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        
      <table className="min-w-full divide-y divide-gray-200">
      <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
      <tr>
      <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
      Member ID</th>
            <th className="px-4 py-2 whitespace-nowrap">User ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Committee ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Remark</th>
            <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">Actions</th>
            </tr>
        </thead>
        <tbody>
          {committeeMembers.map((member) => (
            <tr key={member.committee_member_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{member.committee_member_id}</td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingMember && editingMember.committee_member_id === member.committee_member_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="user_id"
                      value={editingMember.user_id || ""}
                      onChange={handleInputChange}
                      placeholder="User ID"
                    />
                  </div>
                ) : (
                  member.user_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingMember && editingMember.committee_member_id === member.committee_member_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="committee_id"
                      value={editingMember.committee_id || ""}
                      onChange={handleInputChange}
                      placeholder="Committee ID"
                    />
                  </div>
                ) : (
                  member.committee_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingMember && editingMember.committee_member_id === member.committee_member_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="remark"
                      value={editingMember.remark || ""}
                      onChange={handleInputChange}
                      placeholder="Remark"
                    />
                  </div>
                ) : (
                  member.remark
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap sticky right-0  tableHeadEditDelete">
              {editingMember && editingMember.committee_member_id === member.committee_member_id ? (
                  <>
                    <button
                      className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      className="bg-gray-500 text-white px-3 py-1 rounded"
                      onClick={handleUndo}
                    >
                      Undo
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                      onClick={() => handleEdit(member)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                      onClick={() => handleDelete(member.committee_member_id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Register New Committee Member
            </h2>
            <form className="grid grid-cols-3 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="user_id">
                  User ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="user_id"
                  value={newMember.user_id || ""}
                  onChange={handleInputChange}
                  placeholder="User ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.user_id && (
                  <p className="text-red-500">{errors.user_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="committee_id">
                  Committee ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="committee_id"
                  value={newMember.committee_id || ""}
                  onChange={handleInputChange}
                  placeholder="Committee ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.committee_id && (
                  <p className="text-red-500">{errors.committee_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                
                  <textarea
            name="remark"
            value={newMember.remark || ""}
            onChange={handleInputChange}
            placeholder="Remark"
            className="w-full p-2 mb-4 border rounded"
          />
              </div>
              <div className="col-span-3 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommitteeMemberList;
