//pcombinator-frontend-npm6node14/src/pages/AboutUs.jsx

import React from 'react'
import '../styles/AboutUs.css'; 
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <div className="about-us">
      <header className="about-header">
        <h1>About Us</h1>
        <p>Empowering Collaborative Project Management</p>
      </header>
      <section className="about-intro">
        <p>PCombinator is a dynamic platform designed to connect academicians, researchers, mentors, organizations, investors, students, and freshers...</p>
      </section>
      <section className="about-vision-mission">
        <h2>Our Vision and Mission</h2>
        <p><strong>Vision:</strong> To be the leading platform...</p>
        <p><strong>Mission:</strong> To provide tools, resources...</p>
      </section>
      <section className="about-offers">
        <h2>What We Offer</h2>
        <ul>
          <li><strong>For Students:</strong> Live projects...</li>
          <li><strong>For Academicians:</strong> Tools for managing...</li>
          {/* <!-- Other categories --> */}
        </ul>
      </section>
      <section className="about-how-it-works">
        <h2>How It Works</h2>
        <ol>
          <li><strong>Sign Up:</strong> Create your profile...</li>
          <li><strong>Connect:</strong> Join projects...</li>
          {/* <!-- Other steps --> */}
        </ol>
      </section>
      <section className="about-testimonials">
        <h2>Testimonials</h2>
        <blockquote>
          <p>"Thanks to PCombinator, I was able to work on real-world projects..."</p>
          <footer>— User Name, Student</footer>
        </blockquote>
        {/* <!-- Other testimonials --> */}
      </section>
      <section className="about-team">
        <h2>Our Team</h2>
        {/* <!-- Team member cards --> */}
      </section>
      <section className="about-contact">
        <h2>Contact Us</h2>
        <p>Email: contact@pcombinator.com</p>
        <p>Phone: +1234567890</p>
        <Link to="/contactUs" className="contactusbtn">Contact us</Link>
        {/* <!-- Contact form --> */}
      </section>
      <footer className="about-footer">
        {/* <!-- Footer links and social media icons --> */}
      </footer>
    </div>
  );
}

export default AboutUs
