// prutl-frontend-npm6node14/src/components/competitionManagement/CompetitionList.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompetitions,
  createNewCompetition,
  updateCompetition,
  deleteCompetition,
} from "../../redux/slices/competitionSlice.js";

const CompetitionList = () => {
  const dispatch = useDispatch();
  const { competitions, loading, error } = useSelector(
    (state) => state.competitions
  );
  const [editingCompetition, setEditingCompetition] = useState(null);
  const [newCompetition, setNewCompetition] = useState({
    competition_code: "",
    event_id: "",
    category: "",
    type: "",
    start_date: "",
    end_date: "",
    location: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllCompetitions());
  }, [dispatch]);

  const handleDelete = (competitionId) => {
    dispatch(deleteCompetition(competitionId));
  };

  const handleEdit = (competition) => {
    setEditingCompetition({ ...competition });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingCompetition) {
      setEditingCompetition({ ...editingCompetition, [name]: value });
    } else {
      setNewCompetition({ ...newCompetition, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.competition_code)
      newErrors.competition_code = "Competition code is required";
    if (!formData.event_id) newErrors.event_id = "Competition name is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingCompetition) {
      if (editingCompetition.competition_id && editingCompetition) {
        dispatch(
          updateCompetition({
            competitionId: editingCompetition.competition_id,
            data: editingCompetition,
          })
        );
      } else {
        console.error(
          "Error: competitionId or editingCompetition is undefined"
        );
      }
      setEditingCompetition(null);
    } else {
      if (validateForm(newCompetition)) {
        dispatch(createNewCompetition(newCompetition));
        setNewCompetition({
          competition_code: "",
          event_id: "",
          category: "",
          type: "",
          start_date: "",
          end_date: "",
          location: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingCompetition(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading competitions...");
    }
    if (error) {
      console.error("Error fetching competitions:", error);
    }
    if (competitions.length > 0) {
      console.log("Fetched competitions:", competitions);
    }
  }, [loading, error, competitions]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="competition-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Competition
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Competition ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">Competition Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Event ID</th>
              <th className="px-4 py-2 whitespace-nowrap">category</th>
              <th className="px-4 py-2 whitespace-nowrap">Type</th>
              <th className="px-4 py-2 whitespace-nowrap">Start Date</th>
              <th className="px-4 py-2 whitespace-nowrap">End Date</th>
              <th className="px-4 py-2 whitespace-nowrap">Location</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {competitions.map((competition) => (
              <tr key={competition.competition_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {competition.competition_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="competition_code"
                        value={editingCompetition.competition_code || ""}
                        onChange={handleInputChange}
                        placeholder="Competition Code"
                      />
                    </div>
                  ) : (
                    competition.competition_code
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="event_id"
                        value={editingCompetition.event_id || ""}
                        onChange={handleInputChange}
                        placeholder="Competition Name"
                      />
                    </div>
                  ) : (
                    competition.event_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="category"
                        value={editingCompetition.category || ""}
                        onChange={handleInputChange}
                        placeholder="category"
                      />
                    </div>
                  ) : (
                    competition.category
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="type"
                        value={editingCompetition.type || ""}
                        onChange={handleInputChange}
                        placeholder="Type"
                      />
                    </div>
                  ) : (
                    competition.type
                  )}
                </td>

                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="start_date"
                        value={
                          editingCompetition.start_date.split("T")[0] || ""
                        }
                        onChange={handleInputChange}
                        placeholder="Start Date"
                      />
                    </div>
                  ) : (
                    new Date(competition.start_date).toLocaleDateString()
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="end_date"
                        value={editingCompetition.end_date.split("T")[0] || ""}
                        onChange={handleInputChange}
                        placeholder="End Date"
                      />
                    </div>
                  ) : (
                    new Date(competition.end_date).toLocaleDateString()
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="location"
                        value={editingCompetition.location || ""}
                        onChange={handleInputChange}
                        placeholder="Location"
                      />
                    </div>
                  ) : (
                    competition.location
                  )}
                </td>

                <td className="px-4 py-2 whitespace-nowrap">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingCompetition.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    competition.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0  tableHeadEditDelete">
                  {editingCompetition &&
                  editingCompetition.competition_id ===
                    competition.competition_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(competition)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                        onClick={() => handleDelete(competition.competition_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Register New Competition
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="competition_code">
                  Competition Code <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="competition_code"
                  value={
                    (editingCompetition
                      ? editingCompetition.competition_code
                      : newCompetition.competition_code) || ""
                  }
                  onChange={handleInputChange}
                  placeholder="Competition Code"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.competition_code && (
                  <p className="text-red-500">{errors.competition_code}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="event_id">
                  Event ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="event_id"
                  value={
                    (editingCompetition
                      ? editingCompetition.event_id
                      : newCompetition.event_id) || ""
                  }
                  onChange={handleInputChange}
                  placeholder="Event ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.event_id && (
                  <p className="text-red-500">{errors.event_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="category">
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="category"
                  value={
                    (editingCompetition
                      ? editingCompetition.category
                      : newCompetition.category) || ""
                  }
                  onChange={handleInputChange}
                  placeholder="Category"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.category && (
                  <p className="text-red-500">{errors.category}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="type">
                  Type <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="type"
                  value={
                    (editingCompetition
                      ? editingCompetition.type
                      : newCompetition.type) || ""
                  }
                  onChange={handleInputChange}
                  placeholder="Type"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.type && <p className="text-red-500">{errors.type}</p>}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="start_date">
                  Start Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="start_date"
                  value={newCompetition.start_date || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.start_date && (
                  <p className="text-red-500">{errors.start_date}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="end_date">
                  End Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="end_date"
                  value={newCompetition.end_date || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.end_date && (
                  <p className="text-red-500">{errors.end_date}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="location">
                  Location <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="location"
                  value={
                    (editingCompetition
                      ? editingCompetition.location
                      : newCompetition.location) || ""
                  }
                  onChange={handleInputChange}
                  placeholder="Location"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.location && (
                  <p className="text-red-500">{errors.location}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                <textarea
                  name="remark"
                  value={
                    (editingCompetition
                      ? editingCompetition.remark
                      : newCompetition.remark) || ""
                  }
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  className="bg-green-500 text-white px-5 py-2 rounded mr-2"
                  onClick={handleSave}
                >
                  {editingCompetition ? "Update Competition" : "Save"}
                </button>
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompetitionList;
