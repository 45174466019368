import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVenues,
  createNewVenue,
  updateVenue,
  deleteVenue,
} from "../../redux/slices/venueSlice.js";

const VenueList = () => {
  const dispatch = useDispatch();
  const { venues, loading, error } = useSelector((state) => state.venues);
  const [editingVenue, setEditingVenue] = useState(null);
  const [newVenue, setNewVenue] = useState({
    venue_code: "",
    venue_name: "",
    address: "",
    seating_capacity: "",
    phone_number: "",
    contact_person: "",
    city: "",
    county: "",
    state: "",
    country: "",
    pin_code: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllVenues());
  }, [dispatch]);

  const handleDelete = (venueId) => {
    dispatch(deleteVenue(venueId));
  };

  const handleEdit = (venue) => {
    setEditingVenue({ ...venue });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingVenue) {
      setEditingVenue({ ...editingVenue, [name]: value });
    } else {
      setNewVenue({ ...newVenue, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.venue_code) newErrors.venue_code = "Venue Code is required";
    if (!formData.venue_name) newErrors.venue_name = "Venue Name is required";
    if (!formData.address) newErrors.address = "Address is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingVenue) {
      if (editingVenue.venue_id && editingVenue) {
        dispatch(
          updateVenue({
            venueId: editingVenue.venue_id,
            data: editingVenue,
          })
        );
      } else {
        console.error("Error: venueId or editingVenue is undefined");
      }
      setEditingVenue(null);
    } else {
      if (validateForm(newVenue)) {
        dispatch(createNewVenue(newVenue));
        setNewVenue({
          venue_code: "",
          venue_name: "",
          address: "",
          seating_capacity: "",
          phone_number: "",
          contact_person: "",
          city: "",
          county: "",
          state: "",
          country: "",
          pin_code: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingVenue(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading venues...");
    }
    if (error) {
      console.error("Error fetching venues:", error);
    }
    if (venues.length > 0) {
      console.log("Fetched venues:", venues);
    }
  }, [loading, error, venues]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="venue-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Venue
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Venue ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Venue Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Venue Name</th>
              <th className="px-4 py-2 whitespace-nowrap">Address</th>
              <th className="px-4 py-2 whitespace-nowrap">Seating Capacity</th>
              <th className="px-4 py-2 whitespace-nowrap">Phone Number</th>
              <th className="px-4 py-2 whitespace-nowrap">Contact Person</th>
              <th className="px-4 py-2 whitespace-nowrap">City</th>
              <th className="px-4 py-2 whitespace-nowrap">County</th>
              <th className="px-4 py-2 whitespace-nowrap">State</th>
              <th className="px-4 py-2 whitespace-nowrap">Country</th>
              <th className="px-4 py-2 whitespace-nowrap">Pin Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {venues.map((venue) => (
              <tr key={venue.venue_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {venue.venue_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="venue_code"
                        value={editingVenue.venue_code || ""}
                        onChange={handleInputChange}
                        placeholder="Venue Code"
                      />
                    </div>
                  ) : (
                    venue.venue_code
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="venue_name"
                        value={editingVenue.venue_name || ""}
                        onChange={handleInputChange}
                        placeholder="Venue Name"
                      />
                    </div>
                  ) : (
                    venue.venue_name
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="address"
                        value={editingVenue.address || ""}
                        onChange={handleInputChange}
                        placeholder="Address"
                      />
                    </div>
                  ) : (
                    venue.address
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="number"
                        name="seating_capacity"
                        value={editingVenue.seating_capacity || ""}
                        onChange={handleInputChange}
                        placeholder="Seating Capacity"
                      />
                    </div>
                  ) : (
                    venue.seating_capacity
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="phone_number"
                        value={editingVenue.phone_number || ""}
                        onChange={handleInputChange}
                        placeholder="Phone Number"
                      />
                    </div>
                  ) : (
                    venue.phone_number
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="contact_person"
                        value={editingVenue.contact_person || ""}
                        onChange={handleInputChange}
                        placeholder="Contact Person"
                      />
                    </div>
                  ) : (
                    venue.contact_person
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="city"
                        value={editingVenue.city || ""}
                        onChange={handleInputChange}
                        placeholder="City"
                      />
                    </div>
                  ) : (
                    venue.city
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="county"
                        value={editingVenue.county || ""}
                        onChange={handleInputChange}
                        placeholder="County"
                      />
                    </div>
                  ) : (
                    venue.county
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="state"
                        value={editingVenue.state || ""}
                        onChange={handleInputChange}
                        placeholder="State"
                      />
                    </div>
                  ) : (
                    venue.state
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="country"
                        value={editingVenue.country || ""}
                        onChange={handleInputChange}
                        placeholder="Country"
                      />
                    </div>
                  ) : (
                    venue.country
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="pin_code"
                        value={editingVenue.pin_code || ""}
                        onChange={handleInputChange}
                        placeholder="Pin Code"
                      />
                    </div>
                  ) : (
                    venue.pin_code
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingVenue && editingVenue.venue_id === venue.venue_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingVenue.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    venue.remark
                  )}
                </td>
                  <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingVenue &&
                  editingVenue.venue_id === venue.venue_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(venue)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-1 rounded"
                        onClick={() => handleDelete(venue.venue_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
    <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
      <h2 className="text-2xl font-semibold mb-4">Create New Venue</h2>
      <form className="grid grid-cols-4 gap-4">
        <div className="input-group row-span-3">
          <label htmlFor="venue_code">Venue Code <span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            id="venue_code"
            name="venue_code"
            value={newVenue.venue_code}
            onChange={handleInputChange}
            placeholder="Venue Code"
          />
          {errors.venue_code && (
            <p className="text-red-500 text-sm">{errors.venue_code}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="venue_name">Venue Name <span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            id="venue_name"
            name="venue_name"
            value={newVenue.venue_name}
            onChange={handleInputChange}
            placeholder="Venue Name"
          />
          {errors.venue_name && (
            <p className="text-red-500 text-sm">{errors.venue_name}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="address">Address <span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            id="address"
            name="address"
            value={newVenue.address}
            onChange={handleInputChange}
            placeholder="Address"
          />
          {errors.address && (
            <p className="text-red-500 text-sm">{errors.address}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="seating_capacity">Seating Capacity</label>
          <input
            type="number"
            id="seating_capacity"
            name="seating_capacity"
            value={newVenue.seating_capacity}
            onChange={handleInputChange}
            placeholder="Seating Capacity"
          />
          {errors.seating_capacity && (
            <p className="text-red-500 text-sm">{errors.seating_capacity}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="phone_number">Phone Number</label>
          <input
            type="text"
            id="phone_number"
            name="phone_number"
            value={newVenue.phone_number}
            onChange={handleInputChange}
            placeholder="Phone Number"
          />
          {errors.phone_number && (
            <p className="text-red-500 text-sm">{errors.phone_number}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="contact_person">Contact Person</label>
          <input
            type="text"
            id="contact_person"
            name="contact_person"
            value={newVenue.contact_person}
            onChange={handleInputChange}
            placeholder="Contact Person"
          />
          {errors.contact_person && (
            <p className="text-red-500 text-sm">{errors.contact_person}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            value={newVenue.city}
            onChange={handleInputChange}
            placeholder="City"
          />
          {errors.city && (
            <p className="text-red-500 text-sm">{errors.city}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="county">County</label>
          <input
            type="text"
            id="county"
            name="county"
            value={newVenue.county}
            onChange={handleInputChange}
            placeholder="County"
          />
          {errors.county && (
            <p className="text-red-500 text-sm">{errors.county}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            name="state"
            value={newVenue.state}
            onChange={handleInputChange}
            placeholder="State"
          />
          {errors.state && (
            <p className="text-red-500 text-sm">{errors.state}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            value={newVenue.country}
            onChange={handleInputChange}
            placeholder="Country"
          />
          {errors.country && (
            <p className="text-red-500 text-sm">{errors.country}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="pin_code">Pin Code</label>
          <input
            type="text"
            id="pin_code"
            name="pin_code"
            value={newVenue.pin_code}
            onChange={handleInputChange}
            placeholder="Pin Code"
          />
          {errors.pin_code && (
            <p className="text-red-500 text-sm">{errors.pin_code}</p>
          )}
        </div>
        <div className="input-group row-span-3">
          <label htmlFor="remark">Remark</label>
         
          <textarea
                  name="remark"
                  value={newVenue.remark || ""}
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
          {errors.remark && (
            <p className="text-red-500 text-sm">{errors.remark}</p>
          )}
        </div>
        <div className="col-span-4 text-center">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded mr-4"
            type="button"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
)}

    </div>
  );
};

export default VenueList;
