import React from 'react'

const ProjectCollaboration = () => {
  return (
    <div>
      <h1>ProjectCollaboration</h1>
    </div>
  )
}

export default ProjectCollaboration
