import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBookingServices,
  createNewBookingService,
  updateBookingService,
  deleteBookingService,
} from "../../redux/slices/bookingServiceSlice.js";

const BookingServiceList = () => {
  const dispatch = useDispatch();
  const { bookingServices, loading, error } = useSelector(
    (state) => state.bookingServices
  );
  const [editingBookingService, setEditingBookingService] = useState(null);
  const [newBookingService, setNewBookingService] = useState({
    booking_id: "",
    service_id: "",
    quantity: "",
    total_cost: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllBookingServices());
  }, [dispatch]);

  const handleDelete = (bookingServiceId) => {
    dispatch(deleteBookingService(bookingServiceId));
  };

  const handleEdit = (bookingServiceId) => {
    setEditingBookingService({ ...bookingServiceId });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingBookingService) {
      setEditingBookingService({ ...editingBookingService, [name]: value });
    } else {
      setNewBookingService({ ...newBookingService, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.booking_id) newErrors.booking_id = "Booking ID is required";
    if (!formData.service_id) newErrors.service_id = "Service ID is required";
    if (!formData.quantity) newErrors.quantity = "Quantity is required";
    if (!formData.total_cost) newErrors.total_cost = "Total Cost is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingBookingService) {
      if (editingBookingService.booking_service_id && editingBookingService) {
        dispatch(
          updateBookingService({
            bookingServiceId: editingBookingService.booking_service_id,
            data: editingBookingService,
          })
        );
      } else {
        console.error("Error: bookingServiceId or editingBookingService is undefined");
      }
      setEditingBookingService(null);
    } else {
      if (validateForm(newBookingService)) {
        dispatch(createNewBookingService(newBookingService));
        setNewBookingService({
          booking_id: "",
          service_id: "",
          quantity: "",
          total_cost: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingBookingService(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading booking services...");
    }
    if (error) {
      console.error("Error fetching booking services:", error);
    }
    if (bookingServices.length > 0) {
      console.log("Fetched booking services:", bookingServices);
    }
  }, [loading, error, bookingServices]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="booking-service-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Booking Service
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Booking Service ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">Booking ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Service ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Quantity</th>
              <th className="px-4 py-2 whitespace-nowrap">Total Cost</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {bookingServices.map((bookingService) => (
              <tr key={bookingService.booking_service_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {bookingService.booking_service_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingBookingService &&
                  editingBookingService.booking_service_id === bookingService.booking_service_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="booking_id"
                        value={editingBookingService.booking_id || ""}
                        onChange={handleInputChange}
                        placeholder="Booking ID"
                      />
                    </div>
                  ) : (
                    bookingService.booking_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingBookingService &&
                  editingBookingService.booking_service_id === bookingService.booking_service_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="service_id"
                        value={editingBookingService.service_id || ""}
                        onChange={handleInputChange}
                        placeholder="Service ID"
                      />
                    </div>
                  ) : (
                    bookingService.service_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingBookingService &&
                  editingBookingService.booking_service_id === bookingService.booking_service_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="quantity"
                        value={editingBookingService.quantity || ""}
                        onChange={handleInputChange}
                        placeholder="Quantity"
                      />
                    </div>
                  ) : (
                    bookingService.quantity
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingBookingService &&
                  editingBookingService.booking_service_id === bookingService.booking_service_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="total_cost"
                        value={editingBookingService.total_cost || ""}
                        onChange={handleInputChange}
                        placeholder="Total Cost"
                      />
                    </div>
                  ) : (
                    bookingService.total_cost
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingBookingService &&
                  editingBookingService.booking_service_id === bookingService.booking_service_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingBookingService.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    bookingService.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingBookingService &&
                  editingBookingService.booking_service_id === bookingService.booking_service_id ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 text-white px-3 py-1 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                        onClick={() => handleEdit(bookingService)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-1 rounded"
                        onClick={() => handleDelete(bookingService.booking_service_id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal for Creating a New Booking Service */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Create New Booking Service
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="booking_id">Booking ID</label>
                <input
              type="text"
              name="booking_id"
              value={newBookingService.booking_id}
              onChange={handleInputChange}
              placeholder="Booking ID"
            />
            {errors.booking_id && (
              <span className="error-message">{errors.booking_id}</span>
            )}
          </div>
          <div className="input-group row-span-3">
            <label htmlFor="service_id">Service ID</label>
            <input
              type="text"
              name="service_id"
              value={newBookingService.service_id}
              onChange={handleInputChange}
              placeholder="Service ID"
            />
            {errors.service_id && (
              <span className="error-message">{errors.service_id}</span>
            )}
          </div>
          <div className="input-group row-span-3">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="text"
              name="quantity"
              value={newBookingService.quantity}
              onChange={handleInputChange}
              placeholder="Quantity"
            />
            {errors.quantity && (
              <span className="error-message">{errors.quantity}</span>
            )}
          </div>
          <div className="input-group row-span-3">
            <label htmlFor="total_cost">Total Cost</label>
            <input
              type="text"
              name="total_cost"
              value={newBookingService.total_cost}
              onChange={handleInputChange}
              placeholder="Total Cost"
            />
            {errors.total_cost && (
              <span className="error-message">{errors.total_cost}</span>
            )}
          </div>
          <div className="input-group row-span-3">
            <label htmlFor="remark">Remark</label>
            <textarea
              name="remark"
              value={newBookingService.remark}
              onChange={handleInputChange}
              placeholder="Remark"
              className="w-full p-2 mb-4 border rounded"
            />
          </div>
        </form>
        <div className="col-span-4 text-center">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded mr-4"
                  type="button"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                  type="button"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
      </div>
    </div>
  )}
</div>
);
};

export default BookingServiceList;