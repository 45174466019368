// prutl-frontend-npm6node14/src/components/participantManagement/ParticipantList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllParticipants,
  createNewParticipant,
  updateParticipant,
  deleteParticipant,
} from "../../redux/slices/participantSlice.js";

const ParticipantList = () => {
  const dispatch = useDispatch();
  const { participants, loading, error } = useSelector((state) => state.participants);
  const [editingParticipant, setEditingParticipant] = useState(null);
  const [newParticipant, setNewParticipant] = useState({
    user_id: "",
    competition_id: "",
    org_id: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllParticipants());
  }, [dispatch]);

  const handleDelete = (participantId) => {
    dispatch(deleteParticipant(participantId));
  };

  const handleEdit = (participant) => {
    setEditingParticipant({ ...participant });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingParticipant) {
      setEditingParticipant({ ...editingParticipant, [name]: value });
    } else {
      setNewParticipant({ ...newParticipant, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.user_id) newErrors.user_id = "User ID is required";
    if (!formData.competition_id) newErrors.competition_id = "Competition ID is required";
    if (!formData.org_id) newErrors.org_id = "Organization ID is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingParticipant) {
      if (editingParticipant.participant_id && editingParticipant) {
        dispatch(
          updateParticipant({ participantId: editingParticipant.participant_id, data: editingParticipant })
        );
      } else {
        console.error("Error: participantId or editingParticipant is undefined");
      }
      setEditingParticipant(null);
    } else {
      if (validateForm(newParticipant)) {
        dispatch(createNewParticipant(newParticipant));
        setNewParticipant({
          user_id: "",
          competition_id: "",
          org_id: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingParticipant(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading participants...");
    }
    if (error) {
      console.error("Error fetching participants:", error);
    }
    if (participants.length > 0) {
      console.log("Fetched participants:", participants);
    }
  }, [loading, error, participants]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="participant-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Participant
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        
      <table className="min-w-full divide-y divide-gray-200">
      <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete  ">
      <tr>
      <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
      Participant ID</th>
            <th className="px-4 py-2 whitespace-nowrap">User ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Competition ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Organization ID</th>
            <th className="px-4 py-2 whitespace-nowrap">Remark</th>
            <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">Actions</th>
            </tr>
        </thead>
        <tbody>
          {participants.map((participant) => (
            <tr key={participant.participant_id}>
<td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
{participant.participant_id}</td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingParticipant && editingParticipant.participant_id === participant.participant_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="user_id"
                      value={editingParticipant.user_id || ""}
                      onChange={handleInputChange}
                      placeholder="User ID"
                    />
                  </div>
                ) : (
                  participant.user_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingParticipant && editingParticipant.participant_id === participant.participant_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="competition_id"
                      value={editingParticipant.competition_id || ""}
                      onChange={handleInputChange}
                      placeholder="Competition ID"
                    />
                  </div>
                ) : (
                  participant.competition_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingParticipant && editingParticipant.participant_id === participant.participant_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="org_id"
                      value={editingParticipant.org_id || ""}
                      onChange={handleInputChange}
                      placeholder="Organization ID"
                    />
                  </div>
                ) : (
                  participant.org_id
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {editingParticipant && editingParticipant.participant_id === participant.participant_id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      name="remark"
                      value={editingParticipant.remark || ""}
                      onChange={handleInputChange}
                      placeholder="Remark"
                    />
                  </div>
                ) : (
                  participant.remark
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
              {editingParticipant && editingParticipant.participant_id === participant.participant_id ? (
                  <>
                    <button
                      className="bg-green-500 text-white px-4 py-1 mx-5 rounded"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      className="bg-gray-500 text-white px-3 py-1 rounded"
                      onClick={handleUndo}
                    >
                      Undo
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="bg-blue-500 text-white px-5 py-1 mx-5 rounded"
                      onClick={() => handleEdit(participant)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 my-1 rounded"
                      onClick={() => handleDelete(participant.participant_id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Register New Participant
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label htmlFor="user_id">
                  User ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="user_id"
                  value={newParticipant.user_id || ""}
                  onChange={handleInputChange}
                  placeholder="User ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.user_id && (
                  <p className="text-red-500">{errors.user_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="competition_id">
                  Competition ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="competition_id"
                  value={newParticipant.competition_id || ""}
                  onChange={handleInputChange}
                  placeholder="Competition ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.competition_id && (
                  <p className="text-red-500">{errors.competition_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="org_id">
                  Organization ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="org_id"
                  value={newParticipant.org_id || ""}
                  onChange={handleInputChange}
                  placeholder="Organization ID"
                  className="w-full p-2 mb-4 border rounded"
                />
                {errors.org_id && (
                  <p className="text-red-500">{errors.org_id}</p>
                )}
              </div>
              <div className="input-group row-span-3">
                <label htmlFor="remark">Remark</label>
                
                <textarea
            name="remark"
            value={newParticipant.remark || ""}
            onChange={handleInputChange}
            placeholder="Remark"
            className="w-full p-2 mb-4 border rounded"
          />
              </div>
              <div className="col-span-4 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 mt-4 rounded ml-2"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ParticipantList;
