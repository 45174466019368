// prutl-frontend-npm6node14/src/components/eventBookingManagement/EventBookingList.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEventBookings,
  createNewEventBooking,
  updateEventBooking,
  deleteEventBooking,
} from "../../redux/slices/eventBookingSlice.js";

const EventBookingList = () => {
  const dispatch = useDispatch();
  const { eventBookings, loading, error } = useSelector(
    (state) => state.eventBookings
  );
  const [editingEventBooking, setEditingEventBooking] = useState(null);
  const [newEventBooking, setNewEventBooking] = useState({
    booking_code: "",
    event_id: "",
    hall_id: "",
    venue_id: "",
    user_id: "",
    booking_date: "",
    num_of_seats: "",
    total_cost: "",
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getAllEventBookings());
  }, [dispatch]);

  const handleDelete = (bookingId) => {
    dispatch(deleteEventBooking(bookingId));
  };

  const handleEdit = (eventBooking) => {
    setEditingEventBooking({ ...eventBooking });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEventBooking) {
      setEditingEventBooking({ ...editingEventBooking, [name]: value });
    } else {
      setNewEventBooking({ ...newEventBooking, [name]: value });
    }
  };

  const validateForm = (formData) => {
    let newErrors = {};
    if (!formData.booking_code)
      newErrors.booking_code = "Booking Code is required";
    if (!formData.event_id) newErrors.event_id = "Event ID is required";
    if (!formData.hall_id) newErrors.hall_id = "Hall ID is required";
    if (!formData.venue_id) newErrors.venue_id = "Venue ID is required";
    if (!formData.user_id) newErrors.user_id = "User ID is required";
    if (!formData.booking_date)
      newErrors.booking_date = "Booking Date is required";
    if (!formData.num_of_seats)
      newErrors.num_of_seats = "Number of Seats is required";
    if (!formData.total_cost) newErrors.total_cost = "Total Cost is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (editingEventBooking) {
      if (editingEventBooking.booking_id && editingEventBooking) {
        dispatch(
          updateEventBooking({
            bookingId: editingEventBooking.booking_id,
            data: editingEventBooking,
          })
        );
      } else {
        console.error("Error: bookingId or editingEventBooking is undefined");
      }
      setEditingEventBooking(null);
    } else {
      if (validateForm(newEventBooking)) {
        dispatch(createNewEventBooking(newEventBooking));
        setNewEventBooking({
          booking_code: "",
          event_id: "",
          hall_id: "",
          venue_id: "",
          user_id: "",
          booking_date: "",
          num_of_seats: "",
          total_cost: "",
          remark: "",
        });
        setShowModal(false);
      }
    }
  };

  const handleUndo = () => {
    setEditingEventBooking(null);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (loading) {
      console.log("Loading event bookings...");
    }
    if (error) {
      console.error("Error fetching event bookings:", error);
    }
    if (eventBookings.length > 0) {
      console.log("Fetched event bookings:", eventBookings);
    }
  }, [loading, error, eventBookings]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="event-booking-list overflow-auto">
      <button
        className="bg-blue-500 text-white px-4 py-2 m-2 rounded mb-4"
        onClick={toggleModal}
      >
        Create New Event Booking
      </button>
      <div className="table-wrapper max-h-80 overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="whitespace-nowrap sticky top-0 z-30 tableHeadEditDelete">
            <tr>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 left-0 z-20 tableHeadEditDelete">
                Booking ID
              </th>
              <th className="px-4 py-2 whitespace-nowrap">Booking Code</th>
              <th className="px-4 py-2 whitespace-nowrap">Event ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Hall ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Venue ID</th>
              <th className="px-4 py-2 whitespace-nowrap">User ID</th>
              <th className="px-4 py-2 whitespace-nowrap">Booking Date</th>
              <th className="px-4 py-2 whitespace-nowrap">Number of Seats</th>
              <th className="px-4 py-2 whitespace-nowrap">Total Cost</th>
              <th className="px-4 py-2 whitespace-nowrap">Remark</th>
              <th className="px-4 py-2 whitespace-nowrap sticky top-0 right-0 z-20 tableHeadEditDelete">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {eventBookings.map((eventBooking) => (
              <tr key={eventBooking.booking_id}>
                <td className="px-4 py-2 whitespace-nowrap sticky left-0 tableHeadEditDelete">
                  {eventBooking.booking_id}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="booking_code"
                        value={editingEventBooking.booking_code || ""}
                        onChange={handleInputChange}
                        placeholder="Booking Code"
                      />
                    </div>
                  ) : (
                    eventBooking.booking_code
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="event_id"
                        value={editingEventBooking.event_id || ""}
                        onChange={handleInputChange}
                        placeholder="Event ID"
                      />
                    </div>
                  ) : (
                    eventBooking.event_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="hall_id"
                        value={editingEventBooking.hall_id || ""}
                        onChange={handleInputChange}
                        placeholder="Hall ID"
                      />
                    </div>
                  ) : (
                    eventBooking.hall_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="venue_id"
                        value={editingEventBooking.venue_id || ""}
                        onChange={handleInputChange}
                        placeholder="Venue ID"
                      />
                    </div>
                  ) : (
                    eventBooking.venue_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="user_id"
                        value={editingEventBooking.user_id || ""}
                        onChange={handleInputChange}
                        placeholder="User ID"
                      />
                    </div>
                  ) : (
                    eventBooking.user_id
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="date"
                        name="booking_date"
                        value={editingEventBooking.booking_date || ""}
                        onChange={handleInputChange}
                        placeholder="Booking Date"
                      />
                    </div>
                  ) : (
                    eventBooking.booking_date
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="number"
                        name="num_of_seats"
                        value={editingEventBooking.num_of_seats || ""}
                        onChange={handleInputChange}
                        placeholder="Number of Seats"
                      />
                    </div>
                  ) : (
                    eventBooking.num_of_seats
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="number"
                        name="total_cost"
                        value={editingEventBooking.total_cost || ""}
                        onChange={handleInputChange}
                        placeholder="Total Cost"
                      />
                    </div>
                  ) : (
                    eventBooking.total_cost
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="input-group">
                      <input
                        type="text"
                        name="remark"
                        value={editingEventBooking.remark || ""}
                        onChange={handleInputChange}
                        placeholder="Remark"
                      />
                    </div>
                  ) : (
                    eventBooking.remark
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap sticky right-0 tableHeadEditDelete">
                  {editingEventBooking &&
                  editingEventBooking.booking_id === eventBooking.booking_id ? (
                    <div className="button-group">
                      <button
                        className="bg-green-500 text-white px-4 py-2 m-2 rounded"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 m-2 rounded"
                        onClick={handleUndo}
                      >
                        Undo
                      </button>
                    </div>
                  ) : (
                    <div className="button-group">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 m-2 rounded"
                        onClick={() => handleEdit(eventBooking)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 m-2 rounded"
                        onClick={() => handleDelete(eventBooking.booking_id)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center mt-12 z-30">
          <div className="registerCard px-6 py-1 rounded-lg shadow-lg w-full max-w-6xl">
            <h2 className="text-2xl font-semibold mb-4">
              Create New Event Booking
            </h2>
            <form className="grid grid-cols-4 gap-4">
              <div className="input-group row-span-3">
                <label>Booking Code</label>
                <input
                  type="text"
                  id="booking_code"
                  name="booking_code"
                  value={newEventBooking.booking_code}
                  onChange={handleInputChange}
                  placeholder="Booking Code"
                />
                {errors.booking_code && (
                  <span className="text-red-500">{errors.booking_code}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>Event ID</label>
                <input
                  type="text"
                  id="event_id"
                  name="event_id"
                  value={newEventBooking.event_id}
                  onChange={handleInputChange}
                  placeholder="Event ID"
                />
                {errors.event_id && (
                  <span className="text-red-500">{errors.event_id}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>Hall ID</label>
                <input
                  type="text"
                  id="hall_id"
                  name="hall_id"
                  value={newEventBooking.hall_id}
                  onChange={handleInputChange}
                  placeholder="Hall ID"
                />
                {errors.hall_id && (
                  <span className="text-red-500">{errors.hall_id}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>Venue ID</label>
                <input
                  type="text"
                  id="venue_id"
                  name="venue_id"
                  value={newEventBooking.venue_id}
                  onChange={handleInputChange}
                  placeholder="Venue ID"
                />
                {errors.venue_id && (
                  <span className="text-red-500">{errors.venue_id}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>User ID</label>
                <input
                  type="text"
                  id="user_id"
                  name="user_id"
                  value={newEventBooking.user_id}
                  onChange={handleInputChange}
                  placeholder="User ID"
                />
                {errors.user_id && (
                  <span className="text-red-500">{errors.user_id}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>Booking Date</label>
                <input
                  type="date"
                  id="booking_date"
                  name="booking_date"
                  value={newEventBooking.booking_date}
                  onChange={handleInputChange}
                  placeholder="Booking Date"
                />
                {errors.booking_date && (
                  <span className="text-red-500">{errors.booking_date}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>Number of Seats</label>
                <input
                  type="number"
                  id="num_of_seats"
                  name="num_of_seats"
                  value={newEventBooking.num_of_seats}
                  onChange={handleInputChange}
                  placeholder="Number of Seats"
                />
                {errors.num_of_seats && (
                  <span className="text-red-500">{errors.num_of_seats}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>Total Cost</label>
                <input
                  type="number"
                  id="total_cost"
                  name="total_cost"
                  value={newEventBooking.total_cost}
                  onChange={handleInputChange}
                  placeholder="Total Cost"
                />
                {errors.total_cost && (
                  <span className="text-red-500">{errors.total_cost}</span>
                )}
              </div>
              <div className="input-group row-span-3">
                <label>Remark</label>
                <textarea
                  name="remark"
                  value={newEventBooking.remark || ""}
                  onChange={handleInputChange}
                  placeholder="Remark"
                  className="w-full p-2 mb-4 border rounded"
                />
              </div>
            </form>
            <div className="col-span-4 text-center">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded mr-4"
                type="button"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                type="button"
                onClick={toggleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventBookingList;
